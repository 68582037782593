.allris__submenu {
  margin: 1rem 0 2rem 0;
}

.allris__submenu__link {
  color: #ffffff;
  text-decoration: none;
  &:hover {
    color: #ffffff;
  }
}

.allris__submenu__item {
  display: block;
  float: left;
  height: 2rem;
  background-color: #1779ba;
  text-align: center;
  color: white;
  margin-right: 1rem;
  margin: 0 1rem 1rem 0;
  padding: 0.2rem 1rem;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: #2196e4;
  }
  
  &--active {
    background-color: #2196e4 !important;
  }

  &--intern {
    background-color: #417090;
  }
}
