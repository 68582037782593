.infobox {
  background: $white;
  padding: rem-calc(25px);

  @include breakpoint(large) {
    padding: rem-calc(35px);
  }

  h3 {
    font-size: rem-calc(24px);
    color: $body-font-color;
  }
}