.section-newsletter {
  background-color: white;
  padding: rem-calc(35px);

  .column, .moduletable {
    float: none;
  }

  .newsletter-form-wrapper {
    @include breakpoint(medium) {
      display: flex;
    }

    label {
      width: 100px;
      font-size: rem-calc(18px);
    }

    .newsletter-input {
      width: 100%;
      height: auto;

      @include breakpoint(medium) {
        margin: 0 rem-calc(20px) 0 0;
      }
    }

    .send-button {
      @include breakpoint(medium) {
        margin: 0;
      }
    }
  }

  .privacy-accepted-wrapper {
    text-align: right;

    input {
      margin: rem-calc(16px) 0 0 rem-calc(16px);
    }
  }
}