.checkbox.inline {
  display: inline-block;
  margin: 0 rem-calc(16px);

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.bfFormDiv {
  @include flex-grid-row();
  padding: rem-calc(50px) 0;
}

.bfQuickMode {
  @include flex-grid-column();
}

.login-form {
  margin: 0 rem-calc(15px);
  background: $white;
  padding: rem-calc(24px);
  margin-bottom: rem-calc(30px); //
}

.login-pass {
  margin: 0 rem-calc(15px);
}

#system-message {
  margin: 0 rem-calc(15px);
  color: $white;
  background: $alert-color;
  padding: rem-calc(24px);

  a {
    color: $white;
  }

  .alert-heading {
    color: $white;
  }

  .alert-message,
  .alert-warning {
    position: relative;

    .close {
      position: absolute;
      top: 0;
      right: 0;
      text-decoration: none;
      line-height: 1;
      font-size: 26px;
    }
  }
}

//.bfQuickMode {
//  background: $white;
//  padding: rem-calc(20px);
//}

