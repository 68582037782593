#searchForm {
  margin: 0 rem-calc(15px);
  background: $white;
  padding: rem-calc(24px);
  margin-bottom: rem-calc(30px);

  legend, .ordering {
    display: block;
    font-size: rem-calc(18px);
    margin: 0 0 rem-calc(8px) 0;
  }

  .button {
    margin-bottom: 0;
  }
}



.search-page {
  padding: rem-calc(45px) 0;

  @include breakpoint(large) {
    padding: rem-calc(65px) 0;
  }
}

.phrases-box {
  margin-top: rem-calc(18);
  label {
    display: inline-block;
    margin-right: rem-calc(8px);
  }
}

.search-row,
.search-setting-row {
  @include flex-grid-row();
}

.search-row {
  align-items: center;
  margin-bottom: rem-calc(15px);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(25px);
  }
}

.search-setting-row {
  label {
    color: $body-font-color;
    font-size: $global-font-size;
    line-height: 1.5;
    margin-bottom: .5rem;
  }
}


.phrases-row,
.search-input-group {
  @include flex-grid-column(12);

  @include breakpoint(medium) {
    @include flex-grid-column();
  }
}

.search-input-group {
  .inputbox {
    margin: 0;
  }
}

.form-limit,
.search-submit-group {
  @include flex-grid-column(12);

  @include breakpoint(medium) {
    @include flex-grid-column('shrink');
  }

  .btn {
    @include breakpoint(medium) {
      margin: 0;
    }

  }
}

.phrases-row {
 display: flex;

  @include breakpoint(medium down) {
    flex-flow: column;
  }
}

.phrases-group {
  @include breakpoint(medium down) {
    @include flex-grid-column(12);
  }
}

.ordering-box {
  @include breakpoint(medium) {
    margin-left: rem-calc(15px);
  }
}

.form-limit {
  margin-left: auto;
}

.searchintro {
  margin: 0 rem-calc(15px);
  border-top: 1px solid $grey-lighten-1;
  padding: rem-calc(15px) 0;

  @include breakpoint(medium down) {
    margin-top: rem-calc(15px);
  }

  p {
    margin-bottom: 0;
  }
}


.search-results {
  margin: 0 rem-calc(15px);
  border: 1px solid $grey-lighten-1;

  .result-title {
    display: block;
  }
}

.search-results-item {
  background: rgba($white, 1);
  border-bottom: 1px solid $grey-lighten-1;

  padding: rem-calc(20px) rem-calc(15px);

  @include breakpoint(medium) {
    padding: rem-calc(20px) rem-calc(25px);
  }

  &:nth-child(2n) {
    background: rgba($white, 0.5);
  }
}
