.service--fadeout {
  opacity: 0;
  transition: opacity 0.2s;
}

.service--hidden {
  display: none;
  height: 0 !important;
  /*transition: 0.2s;*/
  overflow: hidden;
}

.min-height {
  min-height: 30rem;
}

.serviceitem {
  /*transition: 0.2s;*/
  font-family: "Lato", sans-serif;
  margin-bottom: 0.5rem;
}

.service--fadeout.service_K {
  color: green;
}

.service__indexbar {
  margin: 1rem 0 2rem 0;
}

.service__indexbar__item {
  display: block;
  float: left;
  width: 2rem;
  height: 2rem;
  background-color: #1779ba;
  text-align: center;
  color: white;
  margin-right: 1rem;
  margin: 0 1rem 1rem 0;
  padding: 0.2rem 0;
  box-sizing: border-box;
  cursor: pointer;
}

.service__indexbar__item:hover {
  background-color: #2196e4;
}

/* SIDEBOX */

.service__sidebox {

  padding: 1rem;
  background-color: white;

  &__content {
    padding: 1rem;
  }

  table {
    width: 100% !important;
  }
}