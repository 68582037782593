$nav-width-S: 310px;

.main-wrapper {

  transform: translateZ(0);
  will-change: transform;
  transition: transform 0.3s ease 0s;
  overflow: hidden;

  &.is-visible {
    transform: translateX(-$nav-width-S);
  }
}

.mobile-navigation {
  background: darken($color-mirage, 3%);
  height: 100%;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease 0s;
  width: $nav-width-S;
  z-index: 1;

  &.is-visible {
    transform: translateX(0);
  }

  a, span {
    display: block;
    padding: rem-calc(25px) rem-calc(20px);
    color: $white;
    font-size: rem-calc(17px);
    background: darken($color-mirage, 3%);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid lighten(#2e3233, 5%);
    transform: translateZ(0);
    will-change: transform, opacity;
    transition: transform .3s, opacity .3s;
    text-decoration: none;
  }

  .drilldown {
    width: 100%;
  }

  .is-drilldown-submenu {
    a, span {
      padding: rem-calc(25px) rem-calc(20px);
    }
  }

  .js-drilldown-back a {
    padding: rem-calc(25px) rem-calc(20px);
  }
}