.newsfeed {
  display: table;
  width: 100%;
}

.blog.police {

  padding: rem-calc(3rem) 0;

  .page-header,
  .moduletable {
    @include flex-grid-column(12);
  }
}

.newsfeed-item {
  border: 1px solid $grey-lighten-1;
  border-top: 0;

  &:nth-child(1) {
    border-top: 1px solid $grey-lighten-1;
  }

  &:nth-child(2n) {
    background: rgba($white, 0.8);
  }

  h2 {
    font-size: rem-calc(22px);
    margin: 0 0 rem-calc(5px) 0;
  }
  p {
    &:last-child {
      margin: 0;
    }
  }

  span {
    font-weight: 700;
    color: $grey-darken-2;
  }

  .newsfeed__date {
    width: 10rem;
  }

  .newsfeed-date-block,
  .newsfeed-content-block {
    padding: rem-calc(20px) rem-calc(15px);

    @include breakpoint(medium) {
      display: table-cell;
      padding: rem-calc(30px) rem-calc(25px);
    }
  }
}

.alert-rss {
  padding: 20px 25px;
  border: 1px solid $grey-lighten-1;
  margin: 0 0 25px 0;
  width: 100%;

  p {
    margin: 0;
  }
}
