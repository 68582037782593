.blockquote-container {
  background-origin: content-box;
  background-size: cover;
  width: 100%;
  height: 300px;
  position: relative;

  blockquote {
    @include grid-column(8);
    @include vertical-center;


    p {
      font-size: rem-calc(32px);
      font-family: $header-font-family;
      color: $white;

       &::before {
        content: ' „ ' ' ';
         color: $white;
        font-family: Georgia, serif;
      }

       &::after {
        content: ' ' ' “ ';
         color: $white;
        font-family: Georgia, serif;
      }
    }

    cite {
      color: $white;
      text-transform: uppercase;
      font-weight: 600;
    }

  }
}