.archive {
  padding: rem-calc(45px) 0;

  @include breakpoint(large) {
    padding: rem-calc(65px) 0;
  }
}

.filter-search {
  @include grid-row;
  margin: 0 rem-calc(15px);
  display: flex;
  margin-bottom: rem-calc(30px);
  padding: rem-calc(24px);
  background: $white;

  @include breakpoint(small down) {
    flex-flow: column;
  }


  @include breakpoint(medium) {
    margin-bottom: rem-calc(45px);
    align-items: flex-end;
  }
}

.filter-select-group {
  display: flex;
  align-items: flex-start;

  @include breakpoint(small down) {
    @include flex-grid-column(12, 0);
    flex-flow: column;
  }

  @include breakpoint(medium) {
    @include flex-grid-column(10, 0);
  }
}

.filter__date-col {
  // @include flex-grid-column(12, 0);

  @include breakpoint(small down) {
    width: 100%;
    margin-bottom: 10px;
  }

  .inputbox {
    @extend select;
    box-shadow: none;
    margin: 0;

    b {
      display: none;
    }
  }

  .chzn-drop {
    margin-right: .9375rem;
    margin-left: .9375rem;
    width: 91%;
  }


  @include breakpoint(medium) {
    @include flex-grid-column(4);
  }
}

.filter-submit-group {
  // @include flex-grid-column(12);

  @include breakpoint(medium) {
    @include flex-grid-column('shrink');
  }

  .button {
    margin-bottom: 0;
  }
}

#archive-items {
  border: 1px solid $grey-lighten-1;
  margin: 0 rem-calc(15px);

  .archive-item {
    @include flex-grid-column(12);
    background: rgba($white, 1);
    border-bottom: 1px solid $grey-lighten-1;
    padding: rem-calc(20px) rem-calc(15px);

    @include breakpoint(medium) {
      padding: rem-calc(20px) rem-calc(25px);
    }

    &:nth-child(2n) {
      background: rgba($white, 0.5);
    }

    &:last-child {
      border-bottom: 0;
    }

    h2 {
      font-size: rem-calc(22px);
    }

    dl {
      margin: 0;

      &:after {
        content: '';
        clear: both;
        display: block;
      }

      dt {
        float: left;
        clear: both;
        width: 75px;
      }
      dd {
        float: left;
      }
    }
  }
}