/* ZITAT */

.quote {
    min-height: 17rem;
    display: flex;
    align-items: center;
  }
  
  .quote__title {
    font-family: "Prata", serif;
    font-size: 1.5rem;
  }
  
  .quote__author {
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 1rem;
  }
  