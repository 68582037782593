/* Varaibles - config */
$linePx: 2px;
$borderPx: 3px;
$screen-sm: 767px;
$subcolor1: $grey-lighten-1;
$subcolor2: black;
/* SCSS*/


.org-chart{
  display:block;
  clear: both;
  margin-bottom: 30px;
  position: relative;
  overflow: visible;
  &.cf:before,
  &.cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }
  &.cf:after {
    clear: both;
  }
  /**
  * For IE 6/7 only
  * Include this rule to trigger hasLayout and contain floats.
  */
  &.cf {
    *zoom: 1;
  }

  ul{
    padding: 0;
    margin: rem-calc(15px) 0 0 0;
    list-style: none;
  }

  ul li {
    position: relative;
    display: block;
    margin: 0;

    &:before {
      display: none;
    }

    a {
      text-decoration: none;
    }

  }
  ul li span {
    display: block;
    text-align: center;
    overflow: hidden;
    text-decoration: none;
    color: $grey-lighten-1;
    // background: lighten($color-mirage, 12%);
    background: darken($blue-darken-1, 20%);
    padding: 4px;
    height: 70px;

    &.empty {
      background: transparent;
    }
  }

  ul li a:hover span {
    background: darken($blue-darken-1, 10%);
    text-decoration: none;
  }

  .lvl-a {
    color: $white;
  }

  /* Box colors */
  .lvl-b {
    background: lighten($blue-darken-1, 5%);
    color: $grey-lighten-1;
    height: auto;

    @include breakpoint(medium) {
      height: 85px;
    }
  }

  a:hover .lvl-b {
    background: $blue-darken-1;
  }

  .lvl-b-second {
    background: darken($blue-darken-1, 12%);
  }

  a:hover .lvl-b {
    background: darken($blue-darken-1, 17%);
  }

  .board{
    width:70%;
    margin: 0 auto;

    display: block;
    position: relative;

    &:before{
      content: "";
      display: block;
      position: absolute;
      height: 177px;
      width: 0px;
      border-left:  $linePx solid $subcolor1;
      margin-left: 49%;
      top:  15px;
    }
  }
  /* 1 column */
  ul.columnOne{
    height:90px;
    position: relative;
    width: 100%;
    display: block;
    clear: both;

    li {
      width:30%;
      margin: 0px auto;
      top:20px;
      display: inline-block;
    }
  }

  /* 2 column */
  ul.columnTwo{
    position: relative;
    width: 100%;
    display: block;

    height:90px;
    clear: both;
    li:first-child{
      width:30%;
      float: left;
    }
    li{
      width:30%;
      float: right;
    }
    &:before{
      content: "";
      display: block;
      position: relative;
      width: 80%;
      height: 10px;
      border-top:  $linePx solid $subcolor1;
      margin: 0 auto;
      top: 40px;
    }
  }
  /* 3 column */
  ul.columnThree {
    position: relative;
    width: 100%;
    display: block;
    clear: both;
    margin: 0;
    li:first-child{
      width:30%;
      float: left;
      margin-left:0;
    }
    li{
      width:30%;
      margin-left:5%;
      float: left;
    }
    li:last-child{
      width:30%;
      float: right;
      margin-left:0;
    }

    &:before {
      content: "";
      display: block;
      position: relative;
      width: 255px;
      height: 10px;
      border-top:  $linePx solid $subcolor1;
      margin: 0 auto;
      top: 40px;
      left: 40px
    }

    &.columnThree--two-lines {
      &:before {
        width: 85%;
      }
    }
  }




  /* DEPARTMENTS COLUMNs */
  .departments{
    width: 100%;
    display: block;
    clear: both;
    margin-top: 100px;
    &:before{
      content: "";
      display: block;
      width: 85%;
      height: 22px;
      border-top:  $linePx solid $subcolor1;
      border-left:  $linePx solid $subcolor1;
      border-right:  $linePx solid $subcolor1;
      margin: 0 auto ;
      top:  0px;
    }
  }
  .department {
    border-left:  $linePx solid $subcolor1;
    width: 15.2%;
    float: left;
    margin: 0 4px;
    &:after{
      content: "";
      position:absolute;
      display: block;
      width: 10px;
      height: 47px;
      border-left:  $linePx solid $subcolor1;
      left:50%;
      top:  -22px;
    }
    &:first-child{
      &:after{
        display:none;
      }
    }
    &:last-child{
      &:after{
        display:none;
      }
    }
    span{
      border-left:  1px solid $subcolor1;
    }
    li{
      padding-left: 25px;
      border-bottom:  $linePx solid $subcolor1;
      height: 80px;
    }
    li {

      span {
        background: $grey-lighten-1;
        top: 40px;
        position: absolute;
        z-index: 1;
        width: 95%;
        vertical-align: middle;
        right: 0;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      a:hover span {
        background: darken($grey-lighten-1, 10%);
      }
    }

    .sections{
      margin-top: -20px;
      width: 100%;

      span {
        color: $subcolor2;

        @include breakpoint(medium) {
          font-size: rem-calc(14px);
        }

        @include breakpoint(large) {
          font-size: rem-calc(16px);
        }
      }
    }
  }

  .department-second {
    margin-top: 15px;

    &:after{
      top:  -37px;
      height: 37px;
    }
  }

}

@mixin responsiveClasses() {
  .org-chart{
    .board{
      margin: 0px;
      width:100%;
    }
    .departments{
      &:before{
        border:none;
      }
    }
    .department{
      float: none;
      width: 100%;
      margin-left: 0;
      margin-bottom: 40px;
      &:before{
        content: "";
        display: block;
        position: absolute;
        width: 15px;
        height: 60px;
        border-left:  $linePx solid $subcolor1;
        z-index: 1;
        top: -45px;
        left: 0%;
        margin-left: -2px;
      }
      &:after{
        display: none;
      }
    }
    .department:first-child:before{	display: none;}
  }
}

/* MEDIA QUERIES */
@media all and (max-width: $screen-sm){
  @include responsiveClasses();
}


/*--------- TO BE REMOVED FROM YOUR CSS --*/
/* this is just to display the behaviour of responsive on codepen */
.responsive-content{
  width: $screen-sm;
  margin:0px auto;
  @include responsiveClasses();

}