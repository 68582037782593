figure {

  position: relative;
  overflow: hidden;
  transition: 0.3s;

  a {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    transition: 0.3s;
    opacity: 0;
    //background: rgba($black, 0.7);
    background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba($color-mirage, 0) 0%, rgba($color-mirage, 0.9) 75%) repeat scroll 0 0;
    color: $white;

    span {
      @include absolute-center;
      font-size: rem-calc(18px);
      top: 40%;
      transform: translate(-50%, 0%);
      transition: 0.3s;
    }
  }

  img {
    width: 100%;
    max-width: none;
  }

  &:hover {
    a {
      opacity: 1;

      span {
        transform: translate(-50%, -50%);
        color: $white;
      }
    }
  }
}


.figure-title {
  padding: rem-calc(16px) rem-calc(16px);
  display: block;
  background: $white;
  font-weight: 600;
  color: #757575;
}

.arrow-circle {
  width: 25px;
  height: 25px;
  display: flex;
  border: 2px solid #ccc;
  border-radius: 100%;
  float: right;
  position: relative;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 8px;
    border-color: transparent transparent transparent #ccc;
    display: inline-block;
    margin: 2px 0 0 2px;
  }
}

.readmore-teaser {

  figcaption {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
}

.readmore-teaser__image {
  width: 100%;
}