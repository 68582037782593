.download {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    &::before {
      content: '»';
      margin: 0 0.3125rem 0 0;
    }
  }
#content {
  img.download__icon {
    width: 1.3rem;
    max-width: 100%;
    margin-right: 1rem;
  }
}

.download-row {
  border-bottom: 1px solid $medium-gray;
  padding-bottom: 3rem;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}