.slider-padding--1 {
  @include breakpoint(large) {
    padding-left: rem-calc(150px);
    padding-right: rem-calc(150px);
  }
}

.slider-padding--2 {
  @include breakpoint(large) {
    padding-left: rem-calc(250px);
    padding-right: rem-calc(250px);
  }
}


.slider-padding--3 {
  @include breakpoint(large) {
    padding-right: 35%;
  }
}
