.event {
  &__row {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
  }

  &__wrapper {
    display: flex;
  }

  &__box {
    height: auto !important;
    align-self: stretch;
    flex: 1;
  }

  &__image {
    &__wrapper {
      padding-top: 100%;
      position: relative;
      width: 100%;
      margin-bottom: 1rem;
    }

    &__image {
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: center top;
      background-color: #DEDEDE;
    }
  }

  &__title {
    text-overflow: ellipsis;
  }
}

#blockrandom {
  cursor: pointer;
  background: $white;
}

.event-filter {
  background: $white;
  padding: rem-calc(24px);
  margin-bottom: rem-calc(30px);

  &__row {
    @include grid-row;
  }

  &__label {
    display: block;
    font-size: rem-calc(18px);
    margin: 0 0 rem-calc(8px) 0;
  }

  input,
  select {
    margin: 0;
  }

  &__date-col,
  &__location,
  &__search {
    @include grid-column(12);
    margin: 0;
  }

  &__search {
    .button {
      margin-left: rem-calc(8px);
    }
  }

  &__date-col {
    label {
      margin-right: rem-calc(8px);
    }
  }

  &__search-group {
    display: flex;
  }

  @include breakpoint(medium) {
    &__date-col,
    &__location {
      @include grid-column(6);
    }
  }

  @include breakpoint(large) {
    &__date-col,
    &__location,
    &__search {
      @include grid-column(3);
    }

    &__search {
      @include grid-column(6);
    }
  }
}
