.cookie-disclaimer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #000;
  padding: rem-calc(25px) 0;
  display: none;

  .is-visible {
    display: block;
  }

  .column {
    display: flex;
    align-items: flex-start;
  }

  p {
    color: $white;
    flex: 1 1 0;
    margin: 0 rem-calc(35px) 0 0;
    font-size: rem-calc(14px);
  }

  .cookie-disclaimer-button {
    color: $white;
    flex: 0 0 auto;
    max-width: 100%;
    font-size: rem-calc(14px);
  }
}