// ------------------------------------------
//  In Content-View
// ------------------------------------------

article {

  h1, h2, h3, h4 {
    margin: 0 0 rem-calc(16px) 0;
  }
}

.subhead {
  font-size: rem-calc(18px);
  text-transform: uppercase;
  color: $grey-darken-3;
  display: block;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  // margin: 5px 0 0 0;
  letter-spacing: rem-calc(1px);

  // @include breakpoint(medium) {
  //   margin: 20px 0 0 0;
  // }
  margin-bottom: 1rem;
}

.highlight-paragraph {
  font-size: rem-calc(20px);
  font-weight: 600;
  line-height: rem-calc(30px);
  margin: 0 0 rem-calc(30px) 0;

  @include breakpoint(medium down) {
    font-size: rem-calc(17px);
  }
}