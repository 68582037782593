footer {
  background: $footer-background;
  padding: rem-calc(45px) 0 0 0;
}

.footer-nav-column {
  font-size: rem-calc(14px);
  list-style: none;
  float: left;
  margin-right: 1rem;
  border-left: 1px solid #888;
  padding-left: 1rem;
  word-break: break-word;
  hyphens: auto;
  width: 100%;

  @include breakpoint(medium) {
    width: 30%;
  }

  @include breakpoint(medium only) {
    &:nth-child(4) {
      clear: both;
    }
  }


  @include breakpoint(large) {
    width: 18%;
  }

    a, span {
      color: $white;
      text-decoration: none;
  }

  li {
    list-style: none;

    &:last-child {
      margin: 0 0 0 0;
    }
  }

  .parent:first-child {
    font-weight: 700;
    margin: 0 0 rem-calc(30px) 0;

    li {
      margin: 0 0 rem-calc(30px) 0;
    }
  }

  .nav-child {
    font-weight: 400;
    margin: 0;

    li {
      margin: 0 0 rem-calc(10px) 0;
    }

    a, span {
      color: rgba($white, 0.8);
    }
  }
}

.footer__first {
  padding-bottom: 1rem;
}

.footer__second {
  background-color: $blue-darken-3;
  padding-top: 2rem;
  padding-bottom: 2rem;
}


.footer-meta-menu {
  float: right;
  
  li {
    display: inline-block;
    margin: 0 rem-calc(15px) 0 0;

    &:last-child {
      margin: 0;
    }

    &:hover a { 
      text-decoration: underline;
    }

    a {
      color: $white;
      text-decoration: none;
    }
  }
}

footer a.search-trigger {
  display: none;
}
