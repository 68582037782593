.breadcrumb-container {
  width: 100%;
  padding: rem-calc(5x) 0;
  background: $white;
  border-bottom: 1px solid $grey-lighten-1;
}

.breadcrumb {
  @include grid-row;
  align-items: stretch;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  ul {
    @include grid-column;
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start;
    margin: 0;

  }

  li {
    align-items: center;
    display: flex;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.25 18.333'%3E%3Cpath d='M9.648 8.358L1.553.266c-.355-.354-.93-.354-1.287 0-.355.354-.355.93 0 1.284L7.72 9 .266 16.45c-.355.354-.355.93 0 1.285.355.354.932.354 1.287 0L9.65 9.643c.348-.35.348-.936-.002-1.285z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 18px;
    background-size: 5px;

    &:first-child {
      a span {
        padding: 0.5em 1em 0.5em 0;
      }
    }

    &:last-child {
      background: none;
    }
  }
  /*
  li + li::before {
    content: "→";
  }
  */

  span {
    color: #7a7a7a;
    display: flex;
    justify-content: center;
    padding: 0.5em rem-calc(15px) 0.5em rem-calc(10px);
  }

  a {
    text-decoration: none;

    &:hover {
      color: $primary-color;
    }
  }
}