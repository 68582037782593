// * ———————————————————————————————————————————————————————— * //
// *    MAP
// * ———————————————————————————————————————————————————————— * //

#map {
  &.small {
    height: 20rem;
  }
  &.medium {
    height: 30rem;
  }
  &.large {
    height: 40rem;
  }
}
