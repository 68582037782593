.ggvz-toolbar  {

.ggvz-toolbar-box {
  background: $white;
  padding: 0 rem-calc(16px);
  margin-bottom: 16px;

  @include breakpoint(medium) {
    display: flex;
    justify-content: space-between;
  }

  .profile-edit {
    text-decoration: none;
    display: inline-block;
    padding: rem-calc(16px) 0;
  }
}

  .menu_menu {
    margin: 0;

    li {
      display: inline-block;
      margin-right: 16px;

      @include breakpoint(small down) {
        display: block;
      }

      a {
        text-decoration: none;
        display: inline-block;
        padding: rem-calc(16px) 0;
        border-bottom: 2px solid transparent;
      }

      &:hover,
      &.current {
        a {
          border-color: $blue-darken-1;
        }
      }
    }
  }

}

#gastgeber {
  #login-form {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .userdata {
      width: 100%;
      background: $white;
      padding: rem-calc(24px);
      margin-bottom: rem-calc(30px); //

      .passwort-reset {
        margin-top: rem-calc(15);
      }
    }
  }

  .login-greeting {
    margin-right: rem-calc(16);
  }

  .logout-button {
    .btn {
      margin: 0;
    }
  }


  .btn.jmodedit{
    display: none !important;
  }

  .oho + .login,
  .oho + .logout {
    display: none;
  }

  .btn-primary {
    background: $primary-color;
    color: $white;
  }

  .btn {
    line-height: rem-calc(16);
  }
}

#reservation_calculator {
  margin-top: rem-calc(30);

  .kc_calc_advance h3,
  .kc_calc_sum h3 {
    font-family: $body-font-family;
    color: $body-font-color;
    font-size: rem-calc(16);
  }
}

#date_fromkropen,
#date_tokropen {
  margin: 0 0 rem-calc(16) rem-calc(8);
  width: 70px;

  .icon-calendar {
    background: url("/media/com_koparent/icons/cal_small.svg");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;

    &:before {
      content: '';
    }
  }
}


.table.table-striped.kc_calc_mainTable {
  td {
    padding: 10px 16px;
  }
}

.kc_calc_header {
  text-align: left;
  padding: 10px 16px;

  h3 {
    // font-size: rem-calc(20);
    margin-bottom: 0;
  }

}
