.top-bar {

  .row {
    position: relative;
  }

  .logo {
    position: absolute;
    top: 0;
    left: rem-calc(20px);
    z-index: 2;
    font-size: rem-calc(16px);
  }
}

.menu {
  float: left;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {

}

// ------------------------------------------
//  In Content-View
// ------------------------------------------

.search-trigger {
  position: relative;
  display: block;
  width: 26px;
  height: 44px;
  overflow: hidden;
  white-space: nowrap;
  /* hide text */
  color: transparent;
  z-index: 1;
  font-size: 0;
  float: right;
  margin: 0 0 0 rem-calc(15px);

  @include breakpoint(medium down) {
    display: none !important;
  }

  &::before, &::after {
    /* search icon */
    content: '';
    position: absolute;
    transition: opacity .3s;
    /* Force Hardware Acceleration in WebKit */
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  &::before {
    /* lens */
    top: 6px;
    left: 6px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid $white;
  }

  &::after {
    /* handle */
    height: 2px;
    width: 8px;
    background: $white;
    bottom: 19px;
    right: 0;
    transform: rotate(45deg);
  }

  span {
    /* container for the X icon */
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  span::before, span::after {
    /* close icon */
    content: '';
    position: absolute;
    display: inline-block;
    height: 2px;
    width: 22px;
    top: 50%;
    margin-top: -2px;
    left: 50%;
    margin-left: -11px;
    background: $white;
    opacity: 0;
    /* Force Hardware Acceleration in WebKit */
   transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: opacity .3s, transform .3s;
  }

  span::before {
    transform: rotate(45deg);
  }

  span::after {
    transform: rotate(-45deg);
  }

  &.is-visible {

    &::before, &::after {
      /* hide search icon */
      opacity: 0;
    }

    span::before, span::after {
      /* show close icon */
      opacity: 1;
    }

    span::before {
      transform: rotate(135deg);
    }

    span::after {
      transform: rotate(45deg);
    }
  }
}

.nav-trigger {
  /* vertically align its content */
  height: 100%;
  padding: 0 0 0 rem-calc(20px);
  margin: 0 0 0 rem-calc(20px);
  font-size: rem-calc(14px);
  text-transform: uppercase;
  color: $white;
  font-weight: bold;
  right: 0;
  border-left: 1px solid rgba($white, 0.2);
  display: table;
  float: right;

  span {
    /* vertically align inside parent element */
    display: table-cell;
    vertical-align: middle;
    color: $white;
  }

  em, em::after, em::before {
    /* this is the menu icon */
    display: block;
    position: relative;
    height: 2px;
    width: 22px;
    background-color: $white;
    backface-visibility: hidden;
  }

  em {
    /* this is the menu central line */
    margin: 6px auto 14px;
    transition: background-color .2s;
  }

  em::before, em::after {
    position: absolute;
    content: '';
    left: 0;
    transition: transform .2s;
  }

  em::before {
    /* this is the menu icon top line */
    transform: translateY(-6px);
  }

  em::after {
    /* this is the menu icon bottom line */
    transform: translateY(6px);
  }

  @include breakpoint(xlarge) {
    display: none;
  }
}

.nav-trigger.is-visible {
  em {
    /* transform menu icon into a 'X' icon */
    background-color: rgba($white, 0);
  }

  em::before {
    /* rotate top line */
    transform: rotate(-45deg);
  }

  em::after {
    /* rotate bottom line */
    transform: rotate(45deg);
  }
}



// ------------------------------------------
//  Search
// ------------------------------------------
/*
.search {
  background: $white;
  opacity: 0;
  visibility: hidden;
  height: 0;
  position: absolute;
  top: 119px;
  left: 0;
  width: 100%;
  transition: .3s;
  z-index: 0;

  &.is-visible {
    opacity: 1;
    visibility: visible;
    height: auto;
  }

  label {
    display: none;
  }

  form {
    @include grid-column;
    float: none;
    position: relative;

    input[type='text'] {
      border-radius: 0;
      border: none;
      background: $white;
      padding: rem-calc(30px) 0;
      box-shadow: none;
      margin: 0;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      margin: 0;
      border-radius: 0;
      border: none;
      background: $primary-color;
      color: $white;
    }
  }
}
*/
