.pagination {
  margin: 0 rem-calc(15);

  .counter {
    margin-top: rem-calc(16);
  }

  ul {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      display: inline-block;

      @include breakpoint(medium) {
        margin-right: 8px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .pagenav {
    display: inline-block;
    padding: rem-calc(8) rem-calc(4);
    margin: 0 rem-calc(4) 0 0;
    line-height: 1;
    text-decoration: none;
    width: 28px;
  }

  a.pagenav {

    &:hover {
      background: $blue-darken-1;
      color: $body-background;

      &:before {
        border-color: $body-background;
      }

      &:after {
        border-color: $body-background;
      }
    }
  }

  .active {
    .pagenav {
      background: $blue-darken-1;
      color: $body-background;
    }
  }

  .pagination-next,
  .pagination-end,
  .pagination-start,
  .pagination-prev {
    .pagenav {
      font-size: 0;
      padding: 12px 0 12px 0;

      &:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-top: 2px solid $blue-darken-1;
      }
    }

    span.pagenav {
      &:before {
        border-top: 2px solid $grey-darken-2;
        border-left: 2px solid $grey-darken-2;
      }

      &:after {
        border-top: 2px solid $grey-darken-2;
        border-left: 2px solid $grey-darken-2;
      }
    }
  }


  .pagination-end,
  .pagination-start {
    .pagenav {
      &:after {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-top: 2px solid $blue-darken-1;
      }
    }
  }


  .pagination-start,
  .pagination-prev {
    .pagenav {
      &:before {
        border-left: 2px solid $blue-darken-1;
        transform: rotate(-45deg);
      }
    }
  }
  .pagination-start {

    .pagenav {
      &:after {
        content: '';
        border-top: 2px solid $blue-darken-1;
        border-left: 2px solid $blue-darken-1;
        transform: rotate(-45deg);
        position: relative;
        left: 1px;
      }

      &:before {
        position: relative;
        left: 4px;
      }
    }


  }

  .pagination-prev {
    .pagenav {
      &:before {
        position: relative;
        left: 2px;
      }
    }
  }

  .pagination-next,
  .pagination-end {
    .pagenav {
      &:before {
        border-right: 2px solid $blue-darken-1;
        transform: rotate(45deg);
      }
    }
  }

  .pagination-next {
    .pagenav {
      &:before {
        position: relative;
        right: 2px;
      }
    }
  }

  .pagination-end {

    .pagenav {
      &:before {
        position: relative;
        left: -1px;
      }

      &:after {
        content: '';
        border-top: 2px solid $blue-darken-1;
        border-right: 2px solid $blue-darken-1;
        transform: rotate(45deg);
        position: relative;
        left: -4px;
      }
    }
  }
}