@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
.filter__date-col .inputbox,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select,
.filter__date-col .inputbox {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&xmedium=50em&large=64em&xlarge=73.4375em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #F5F5F5;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #3F3F3F;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }


select,
.filter__date-col .inputbox {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
.filter__date-col .inputbox,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' '; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 50em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media screen and (min-width: 73.4375em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 50em) {
  .xmedium-1 {
    width: 8.33333%; }
  .xmedium-push-1 {
    position: relative;
    left: 8.33333%; }
  .xmedium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .xmedium-offset-0 {
    margin-left: 0%; }
  .xmedium-2 {
    width: 16.66667%; }
  .xmedium-push-2 {
    position: relative;
    left: 16.66667%; }
  .xmedium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .xmedium-offset-1 {
    margin-left: 8.33333%; }
  .xmedium-3 {
    width: 25%; }
  .xmedium-push-3 {
    position: relative;
    left: 25%; }
  .xmedium-pull-3 {
    position: relative;
    left: -25%; }
  .xmedium-offset-2 {
    margin-left: 16.66667%; }
  .xmedium-4 {
    width: 33.33333%; }
  .xmedium-push-4 {
    position: relative;
    left: 33.33333%; }
  .xmedium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .xmedium-offset-3 {
    margin-left: 25%; }
  .xmedium-5 {
    width: 41.66667%; }
  .xmedium-push-5 {
    position: relative;
    left: 41.66667%; }
  .xmedium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .xmedium-offset-4 {
    margin-left: 33.33333%; }
  .xmedium-6 {
    width: 50%; }
  .xmedium-push-6 {
    position: relative;
    left: 50%; }
  .xmedium-pull-6 {
    position: relative;
    left: -50%; }
  .xmedium-offset-5 {
    margin-left: 41.66667%; }
  .xmedium-7 {
    width: 58.33333%; }
  .xmedium-push-7 {
    position: relative;
    left: 58.33333%; }
  .xmedium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .xmedium-offset-6 {
    margin-left: 50%; }
  .xmedium-8 {
    width: 66.66667%; }
  .xmedium-push-8 {
    position: relative;
    left: 66.66667%; }
  .xmedium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .xmedium-offset-7 {
    margin-left: 58.33333%; }
  .xmedium-9 {
    width: 75%; }
  .xmedium-push-9 {
    position: relative;
    left: 75%; }
  .xmedium-pull-9 {
    position: relative;
    left: -75%; }
  .xmedium-offset-8 {
    margin-left: 66.66667%; }
  .xmedium-10 {
    width: 83.33333%; }
  .xmedium-push-10 {
    position: relative;
    left: 83.33333%; }
  .xmedium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .xmedium-offset-9 {
    margin-left: 75%; }
  .xmedium-11 {
    width: 91.66667%; }
  .xmedium-push-11 {
    position: relative;
    left: 91.66667%; }
  .xmedium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .xmedium-offset-10 {
    margin-left: 83.33333%; }
  .xmedium-12 {
    width: 100%; }
  .xmedium-offset-11 {
    margin-left: 91.66667%; }
  .xmedium-up-1 > .column, .xmedium-up-1 > .columns {
    float: left;
    width: 100%; }
    .xmedium-up-1 > .column:nth-of-type(1n), .xmedium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xmedium-up-1 > .column:nth-of-type(1n+1), .xmedium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xmedium-up-1 > .column:last-child, .xmedium-up-1 > .columns:last-child {
      float: left; }
  .xmedium-up-2 > .column, .xmedium-up-2 > .columns {
    float: left;
    width: 50%; }
    .xmedium-up-2 > .column:nth-of-type(1n), .xmedium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xmedium-up-2 > .column:nth-of-type(2n+1), .xmedium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xmedium-up-2 > .column:last-child, .xmedium-up-2 > .columns:last-child {
      float: left; }
  .xmedium-up-3 > .column, .xmedium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xmedium-up-3 > .column:nth-of-type(1n), .xmedium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xmedium-up-3 > .column:nth-of-type(3n+1), .xmedium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xmedium-up-3 > .column:last-child, .xmedium-up-3 > .columns:last-child {
      float: left; }
  .xmedium-up-4 > .column, .xmedium-up-4 > .columns {
    float: left;
    width: 25%; }
    .xmedium-up-4 > .column:nth-of-type(1n), .xmedium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xmedium-up-4 > .column:nth-of-type(4n+1), .xmedium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xmedium-up-4 > .column:last-child, .xmedium-up-4 > .columns:last-child {
      float: left; }
  .xmedium-up-5 > .column, .xmedium-up-5 > .columns {
    float: left;
    width: 20%; }
    .xmedium-up-5 > .column:nth-of-type(1n), .xmedium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xmedium-up-5 > .column:nth-of-type(5n+1), .xmedium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xmedium-up-5 > .column:last-child, .xmedium-up-5 > .columns:last-child {
      float: left; }
  .xmedium-up-6 > .column, .xmedium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xmedium-up-6 > .column:nth-of-type(1n), .xmedium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xmedium-up-6 > .column:nth-of-type(6n+1), .xmedium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xmedium-up-6 > .column:last-child, .xmedium-up-6 > .columns:last-child {
      float: left; }
  .xmedium-up-7 > .column, .xmedium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xmedium-up-7 > .column:nth-of-type(1n), .xmedium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xmedium-up-7 > .column:nth-of-type(7n+1), .xmedium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xmedium-up-7 > .column:last-child, .xmedium-up-7 > .columns:last-child {
      float: left; }
  .xmedium-up-8 > .column, .xmedium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xmedium-up-8 > .column:nth-of-type(1n), .xmedium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xmedium-up-8 > .column:nth-of-type(8n+1), .xmedium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xmedium-up-8 > .column:last-child, .xmedium-up-8 > .columns:last-child {
      float: left; }
  .xmedium-collapse > .column, .xmedium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xmedium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xmedium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xmedium-uncollapse > .column, .xmedium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .xmedium-centered {
    margin-right: auto;
    margin-left: auto; }
    .xmedium-centered, .xmedium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xmedium-uncentered,
  .xmedium-push-0,
  .xmedium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 73.4375em) {
  .xlarge-1 {
    width: 8.33333%; }
  .xlarge-push-1 {
    position: relative;
    left: 8.33333%; }
  .xlarge-pull-1 {
    position: relative;
    left: -8.33333%; }
  .xlarge-offset-0 {
    margin-left: 0%; }
  .xlarge-2 {
    width: 16.66667%; }
  .xlarge-push-2 {
    position: relative;
    left: 16.66667%; }
  .xlarge-pull-2 {
    position: relative;
    left: -16.66667%; }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .xlarge-3 {
    width: 25%; }
  .xlarge-push-3 {
    position: relative;
    left: 25%; }
  .xlarge-pull-3 {
    position: relative;
    left: -25%; }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .xlarge-4 {
    width: 33.33333%; }
  .xlarge-push-4 {
    position: relative;
    left: 33.33333%; }
  .xlarge-pull-4 {
    position: relative;
    left: -33.33333%; }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .xlarge-5 {
    width: 41.66667%; }
  .xlarge-push-5 {
    position: relative;
    left: 41.66667%; }
  .xlarge-pull-5 {
    position: relative;
    left: -41.66667%; }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .xlarge-6 {
    width: 50%; }
  .xlarge-push-6 {
    position: relative;
    left: 50%; }
  .xlarge-pull-6 {
    position: relative;
    left: -50%; }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .xlarge-7 {
    width: 58.33333%; }
  .xlarge-push-7 {
    position: relative;
    left: 58.33333%; }
  .xlarge-pull-7 {
    position: relative;
    left: -58.33333%; }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .xlarge-8 {
    width: 66.66667%; }
  .xlarge-push-8 {
    position: relative;
    left: 66.66667%; }
  .xlarge-pull-8 {
    position: relative;
    left: -66.66667%; }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .xlarge-9 {
    width: 75%; }
  .xlarge-push-9 {
    position: relative;
    left: 75%; }
  .xlarge-pull-9 {
    position: relative;
    left: -75%; }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .xlarge-10 {
    width: 83.33333%; }
  .xlarge-push-10 {
    position: relative;
    left: 83.33333%; }
  .xlarge-pull-10 {
    position: relative;
    left: -83.33333%; }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .xlarge-11 {
    width: 91.66667%; }
  .xlarge-push-11 {
    position: relative;
    left: 91.66667%; }
  .xlarge-pull-11 {
    position: relative;
    left: -91.66667%; }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .xlarge-12 {
    width: 100%; }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xlarge-up-1 > .column:nth-of-type(1n), .xlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-1 > .column:nth-of-type(1n+1), .xlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xlarge-up-1 > .column:last-child, .xlarge-up-1 > .columns:last-child {
      float: left; }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xlarge-up-2 > .column:nth-of-type(1n), .xlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-2 > .column:nth-of-type(2n+1), .xlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xlarge-up-2 > .column:last-child, .xlarge-up-2 > .columns:last-child {
      float: left; }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xlarge-up-3 > .column:nth-of-type(1n), .xlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-3 > .column:nth-of-type(3n+1), .xlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xlarge-up-3 > .column:last-child, .xlarge-up-3 > .columns:last-child {
      float: left; }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xlarge-up-4 > .column:nth-of-type(1n), .xlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-4 > .column:nth-of-type(4n+1), .xlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xlarge-up-4 > .column:last-child, .xlarge-up-4 > .columns:last-child {
      float: left; }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xlarge-up-5 > .column:nth-of-type(1n), .xlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-5 > .column:nth-of-type(5n+1), .xlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xlarge-up-5 > .column:last-child, .xlarge-up-5 > .columns:last-child {
      float: left; }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xlarge-up-6 > .column:nth-of-type(1n), .xlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-6 > .column:nth-of-type(6n+1), .xlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xlarge-up-6 > .column:last-child, .xlarge-up-6 > .columns:last-child {
      float: left; }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xlarge-up-7 > .column:nth-of-type(1n), .xlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-7 > .column:nth-of-type(7n+1), .xlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xlarge-up-7 > .column:last-child, .xlarge-up-7 > .columns:last-child {
      float: left; }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xlarge-up-8 > .column:nth-of-type(1n), .xlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-8 > .column:nth-of-type(8n+1), .xlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xlarge-up-8 > .column:last-child, .xlarge-up-8 > .columns:last-child {
      float: left; }
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .xlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xlarge-centered, .xlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xlarge-uncentered,
  .xlarge-push-0,
  .xlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 25px;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Prata", serif;
  font-style: normal;
  font-weight: normal;
  color: #5171B0;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 2.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 2.5rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #5171B0;
  text-decoration: underline;
  cursor: pointer; }
  a:hover, a:focus {
    color: #456198;
    text-decoration: none; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 25px;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 0;
  list-style-type: none; }

ol {
  margin-left: 0; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 25px;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 50em) {
  .xmedium-text-left {
    text-align: left; }
  .xmedium-text-right {
    text-align: right; }
  .xmedium-text-center {
    text-align: center; }
  .xmedium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

@media screen and (min-width: 73.4375em) {
  .xlarge-text-left {
    text-align: left; }
  .xlarge-text-right {
    text-align: right; }
  .xlarge-text-center {
    text-align: center; }
  .xlarge-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #1779ba;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #14679e;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #1779ba;
    color: #fefefe; }
    .button.primary:hover, .button.primary:focus {
      background-color: #126195;
      color: #fefefe; }
  .button.secondary {
    background-color: #767676;
    color: #fefefe; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button.success {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert {
    background-color: #cc4b37;
    color: #fefefe; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #1779ba;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #1779ba;
        color: #fefefe; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #fefefe; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #0a0a0a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #fefefe; }
  .button.hollow {
    border: 1px solid #1779ba;
    color: #1779ba; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #1779ba;
        color: #1779ba; }
    .button.hollow.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #1779ba;
          color: #1779ba; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear {
    border: 1px solid #1779ba;
    color: #1779ba; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #1779ba;
        color: #1779ba; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #1779ba;
          color: #1779ba; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #767676; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: none;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: none;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 2.5rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

.input-group .input-group-button {
  display: table-cell; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }


select,
.filter__date-col .inputbox {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    
    select,
    .filter__date-col .inputbox {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus, .filter__date-col .inputbox:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: none;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled, .filter__date-col .inputbox:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand, .filter__date-col .inputbox::-ms-expand {
    display: none; }
  select[multiple], .filter__date-col .inputbox[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.button-group {
  margin-bottom: 1rem;
  font-size: 0; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' '; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded {
    margin-right: -1px; }
    .button-group.expanded::before, .button-group.expanded::after {
      display: none; }
    .button-group.expanded .button:first-child:last-child {
      width: 100%; }
    .button-group.expanded .button:first-child:nth-last-child(2), .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button {
      display: inline-block;
      width: calc(50% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(2):last-child, .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(3), .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button {
      display: inline-block;
      width: calc(33.33333% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(3):last-child, .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(4), .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button {
      display: inline-block;
      width: calc(25% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(4):last-child, .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(5), .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button {
      display: inline-block;
      width: calc(20% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(5):last-child, .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(6), .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button {
      display: inline-block;
      width: calc(16.66667% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(6):last-child, .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button:last-child {
        margin-right: -6px; }
  .button-group.primary .button {
    background-color: #1779ba;
    color: #fefefe; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #126195;
      color: #fefefe; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #fefefe; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #fefefe; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
    width: 100%; }
    .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
      margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      width: auto;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      width: auto;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #3F3F3F; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #d7ecfa;
    color: #3F3F3F; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #3F3F3F; }
  .callout.success {
    background-color: #e1faea;
    color: #3F3F3F; }
  .callout.warning {
    background-color: #fff3d9;
    color: #3F3F3F; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #3F3F3F; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #3F3F3F; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.card-image {
  min-height: 1px; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu .filter__date-col .inputbox,
  .filter__date-col .menu .inputbox,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu li, .menu.horizontal li {
    display: inline-block; }
  .menu.vertical li {
    display: block; }
  .menu.expanded {
    display: table;
    width: 100%; }
    .menu.expanded > li {
      display: table-cell;
      vertical-align: middle; }
  .menu.simple li + li {
    margin-left: 1rem; }
  .menu.simple a {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal li {
      display: inline-block; }
    .menu.medium-vertical li {
      display: block; }
    .menu.medium-expanded {
      display: table;
      width: 100%; }
      .menu.medium-expanded > li {
        display: table-cell;
        vertical-align: middle; }
    .menu.medium-simple {
      display: table;
      width: 100%; }
      .menu.medium-simple > li {
        display: table-cell;
        vertical-align: middle; } }
  @media print, screen and (min-width: 50em) {
    .menu.xmedium-horizontal li {
      display: inline-block; }
    .menu.xmedium-vertical li {
      display: block; }
    .menu.xmedium-expanded {
      display: table;
      width: 100%; }
      .menu.xmedium-expanded > li {
        display: table-cell;
        vertical-align: middle; }
    .menu.xmedium-simple {
      display: table;
      width: 100%; }
      .menu.xmedium-simple > li {
        display: table-cell;
        vertical-align: middle; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal li {
      display: inline-block; }
    .menu.large-vertical li {
      display: block; }
    .menu.large-expanded {
      display: table;
      width: 100%; }
      .menu.large-expanded > li {
        display: table-cell;
        vertical-align: middle; }
    .menu.large-simple {
      display: table;
      width: 100%; }
      .menu.large-simple > li {
        display: table-cell;
        vertical-align: middle; } }
  @media screen and (min-width: 73.4375em) {
    .menu.xlarge-horizontal li {
      display: inline-block; }
    .menu.xlarge-vertical li {
      display: block; }
    .menu.xlarge-expanded {
      display: table;
      width: 100%; }
      .menu.xlarge-expanded > li {
        display: table-cell;
        vertical-align: middle; }
    .menu.xlarge-simple {
      display: table;
      width: 100%; }
      .menu.xlarge-simple > li {
        display: table-cell;
        vertical-align: middle; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons img,
  .menu.icons i,
  .menu.icons svg {
    vertical-align: middle; }
    .menu.icons img + span,
    .menu.icons i + span,
    .menu.icons svg + span {
      vertical-align: middle; }
  .menu.icon-top img,
  .menu.icon-top i,
  .menu.icon-top svg, .menu.icon-right img,
  .menu.icon-right i,
  .menu.icon-right svg, .menu.icon-bottom img,
  .menu.icon-bottom i,
  .menu.icon-bottom svg, .menu.icon-left img,
  .menu.icon-left i,
  .menu.icon-left svg {
    vertical-align: middle; }
    .menu.icon-top img + span,
    .menu.icon-top i + span,
    .menu.icon-top svg + span, .menu.icon-right img + span,
    .menu.icon-right i + span,
    .menu.icon-right svg + span, .menu.icon-bottom img + span,
    .menu.icon-bottom i + span,
    .menu.icon-bottom svg + span, .menu.icon-left img + span,
    .menu.icon-left i + span,
    .menu.icon-left svg + span {
      vertical-align: middle; }
  .menu.icon-left li a img,
  .menu.icon-left li a i,
  .menu.icon-left li a svg {
    margin-right: 0.25rem;
    display: inline-block; }
  .menu.icon-right li a img,
  .menu.icon-right li a i,
  .menu.icon-right li a svg {
    margin-left: 0.25rem;
    display: inline-block; }
  .menu.icon-top li a {
    text-align: center; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      display: block;
      margin: 0 auto 0.25rem; }
  .menu.icon-bottom li a {
    text-align: center; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      display: block;
      margin: 0.25rem auto 0; }
  .menu .is-active > a {
    background: #1779ba;
    color: #fefefe; }
  .menu .active > a {
    background: #1779ba;
    color: #fefefe; }
  .menu.align-left {
    text-align: left; }
  .menu.align-right {
    text-align: right; }
    .menu.align-right .submenu li {
      text-align: left; }
    .menu.align-right.vertical .submenu li {
      text-align: right; }
    .menu.align-right .nested {
      margin-right: 1rem;
      margin-left: 0; }
  .menu.align-center {
    text-align: center; }
    .menu.align-center .submenu li {
      text-align: left; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  text-align: center; }
  .menu-centered > .menu .submenu li {
    text-align: left; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefe;
    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fefefe; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear; }
  .drilldown .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .drilldown .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }
  .drilldown .is-drilldown-submenu a {
    padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }
  .drilldown .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent rgba(33, 150, 243, 0.3); }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent rgba(33, 150, 243, 0.3); }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent rgba(33, 150, 243, 0.3) transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent rgba(33, 150, 243, 0.3) transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #F5F5F5;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 1rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #1779ba;
  color: #fefefe; }
  .label.primary {
    background: #1779ba;
    color: #fefefe; }
  .label.secondary {
    background: #767676;
    color: #fefefe; }
  .label.success {
    background: #3adb76;
    color: #3F3F3F; }
  .label.warning {
    background: #ffae00;
    color: #3F3F3F; }
  .label.alert {
    background: #cc4b37;
    color: #fefefe; }

.media-object {
  display: block;
  margin-bottom: 1rem; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      display: block; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  display: table-cell;
  vertical-align: top; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.middle {
    vertical-align: middle; }
  .media-object-section.bottom {
    vertical-align: bottom; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  thead,
  tbody,
  tfoot {
    border: 1px solid #f1f1f1;
    background-color: #fefefe; }
  caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  thead {
    background: #f8f8f8;
    color: #3F3F3F; }
  tfoot {
    background: #f1f1f1;
    color: #3F3F3F; }
  thead tr,
  tfoot tr {
    background: transparent; }
  thead th,
  thead td,
  tfoot th,
  tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  tbody th,
  tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f1f1f1; }
  table.unstriped tbody {
    background-color: #fefefe; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f1f1f1;
      background-color: #fefefe; }

@media screen and (max-width: 49.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f3f3f3; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(23, 121, 186, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe; }
  .title-bar::before, .title-bar::after {
    display: table;
    content: ' '; }
  .title-bar::after {
    clear: both; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left {
  float: left; }

.title-bar-right {
  float: right;
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.top-bar {
  padding: 1.25rem 0; }
  .top-bar::before, .top-bar::after {
    display: table;
    content: ' '; }
  .top-bar::after {
    clear: both; }
  .top-bar,
  .top-bar ul {
    background-color: #192233; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar .top-bar-left,
    .top-bar .top-bar-right {
      width: auto; } }
  @media screen and (max-width: 49.9375em) {
    .top-bar.stacked-for-medium .top-bar-left,
    .top-bar.stacked-for-medium .top-bar-right {
      width: 100%; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-xmedium .top-bar-left,
    .top-bar.stacked-for-xmedium .top-bar-right {
      width: 100%; } }
  @media screen and (max-width: 73.375em) {
    .top-bar.stacked-for-large .top-bar-left,
    .top-bar.stacked-for-large .top-bar-right {
      width: 100%; } }
  @media screen and (max-width: 89.9375em) {
    .top-bar.stacked-for-xlarge .top-bar-left,
    .top-bar.stacked-for-xlarge .top-bar-right {
      width: 100%; } }

.top-bar-title {
  display: inline-block;
  float: left;
  padding: 0.5rem 1rem 0.5rem 0; }
  .top-bar-title .menu-icon {
    bottom: 2px; }

.top-bar-left {
  float: left; }

.top-bar-right {
  float: right; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 49.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 50em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 50em) {
  .hide-for-xmedium {
    display: none !important; } }

@media screen and (max-width: 49.9375em) {
  .show-for-xmedium {
    display: none !important; } }

@media screen and (min-width: 50em) and (max-width: 63.9375em) {
  .hide-for-xmedium-only {
    display: none !important; } }

@media screen and (max-width: 49.9375em), screen and (min-width: 64em) {
  .show-for-xmedium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 73.375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 73.4375em) {
  .show-for-large-only {
    display: none !important; } }

@media screen and (min-width: 73.4375em) {
  .hide-for-xlarge {
    display: none !important; } }

@media screen and (max-width: 73.375em) {
  .show-for-xlarge {
    display: none !important; } }

@media screen and (min-width: 73.4375em) and (max-width: 89.9375em) {
  .hide-for-xlarge-only {
    display: none !important; } }

@media screen and (max-width: 73.375em), screen and (min-width: 90em) {
  .show-for-xlarge-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

.text-hide {
  font: 0/0 a !important;
  color: transparent !important;
  text-shadow: none !important;
  background-color: transparent !important;
  border: 0 !important; }

.text-truncate {
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-wrap {
  word-wrap: break-word !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-overline {
  text-decoration: overline !important; }

.text-underline {
  text-decoration: underline !important; }

.text-line-through {
  text-decoration: line-through !important; }

.font-wide {
  letter-spacing: 0.25rem; }

.font-normal {
  font-weight: normal; }

.font-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic !important; }

ul.list-disc {
  list-style-type: disc !important; }

ul.list-circle {
  list-style-type: circle !important; }

ul.list-square {
  list-style-type: square !important; }

ol.list-decimal {
  list-style-type: decimal !important; }

ol.list-lower-alpha {
  list-style-type: lower-alpha !important; }

ol.list-lower-latin {
  list-style-type: lower-latin !important; }

ol.list-lower-roman {
  list-style-type: lower-roman !important; }

ol.list-upper-alpha {
  list-style-type: upper-alpha !important; }

ol.list-upper-latin {
  list-style-type: upper-latin !important; }

ol.list-upper-roman {
  list-style-type: upper-roman !important; }

.rounded {
  border-radius: 5000px !important; }

.radius {
  border-radius: 0.1875rem; }

.bordered {
  border: 0.0625rem solid #cacaca; }

.shadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }

.arrow-down {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0a0a0a transparent transparent; }

.arrow-up {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #0a0a0a; }

.arrow-right {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0a0a0a; }

.arrow-left {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0a0a0a transparent transparent; }

.separator-center {
  text-align: center !important; }
  .separator-center::before, .separator-center::after {
    display: table;
    content: ' '; }
  .separator-center::after {
    clear: both; }
  .separator-center::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #1779ba;
    margin: 1rem auto 0; }

.separator-left {
  text-align: left !important; }
  .separator-left::before, .separator-left::after {
    display: table;
    content: ' '; }
  .separator-left::after {
    clear: both; }
  .separator-left::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #1779ba;
    margin: 1rem auto 0;
    margin-left: 0 !important; }

.separator-right {
  text-align: right !important; }
  .separator-right::before, .separator-right::after {
    display: table;
    content: ' '; }
  .separator-right::after {
    clear: both; }
  .separator-right::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #1779ba;
    margin: 1rem auto 0;
    margin-right: 0 !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-x-visible {
  overflow-x: visible !important; }

.overflow-y-visible {
  overflow-y: visible !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-x-hidden {
  overflow-x: hidden !important; }

.overflow-y-hidden {
  overflow-y: hidden !important; }

.overflow-scroll {
  overflow: scroll !important; }

.overflow-x-scroll {
  overflow-x: scroll !important; }

.overflow-y-scroll {
  overflow-y: scroll !important; }

.display-inline {
  display: inline !important; }

.display-inline-block {
  display: inline-block !important; }

.display-block {
  display: block !important; }

.display-table {
  display: table !important; }

.display-table-cell {
  display: table-cell !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-fixed-top {
  position: fixed !important;
  top: 0rem !important;
  right: 0rem !important;
  left: 0rem !important;
  z-index: 975; }

.position-fixed-bottom {
  position: fixed !important;
  right: 0rem !important;
  bottom: 0rem !important;
  left: 0rem !important;
  z-index: 975; }

.border-box {
  box-sizing: border-box !important; }

.border-none {
  border: none !important; }

.width-25 {
  width: 25% !important; }

.width-50 {
  width: 50% !important; }

.width-75 {
  width: 75% !important; }

.width-100 {
  width: 100% !important; }

.height-25 {
  height: 25% !important; }

.height-50 {
  height: 50% !important; }

.height-75 {
  height: 75% !important; }

.height-100 {
  height: 100% !important; }

.max-width-100 {
  max-width: 100% !important; }

.max-height-100 {
  max-height: 100% !important; }

.margin-0 {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-bottom: 0rem !important;
  margin-left: 0rem !important; }

.padding-0 {
  padding-top: 0rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0rem !important; }

.margin-top-0 {
  margin-top: 0rem !important; }

.padding-top-0 {
  padding-top: 0rem !important; }

.margin-right-0 {
  margin-right: 0rem !important; }

.padding-right-0 {
  padding-right: 0rem !important; }

.margin-bottom-0 {
  margin-bottom: 0rem !important; }

.padding-bottom-0 {
  padding-bottom: 0rem !important; }

.margin-left-0 {
  margin-left: 0rem !important; }

.padding-left-0 {
  padding-left: 0rem !important; }

.margin-horizontal-0 {
  margin-right: 0rem !important;
  margin-left: 0rem !important; }

.padding-horizontal-0 {
  padding-right: 0rem !important;
  padding-left: 0rem !important; }

.margin-vertical-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important; }

.padding-vertical-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important; }

.margin-1 {
  margin-top: 1rem !important;
  margin-right: 1rem !important;
  margin-bottom: 1rem !important;
  margin-left: 1rem !important; }

.padding-1 {
  padding-top: 1rem !important;
  padding-right: 1rem !important;
  padding-bottom: 1rem !important;
  padding-left: 1rem !important; }

.margin-top-1 {
  margin-top: 1rem !important; }

.padding-top-1 {
  padding-top: 1rem !important; }

.margin-right-1 {
  margin-right: 1rem !important; }

.padding-right-1 {
  padding-right: 1rem !important; }

.margin-bottom-1 {
  margin-bottom: 1rem !important; }

.padding-bottom-1 {
  padding-bottom: 1rem !important; }

.margin-left-1 {
  margin-left: 1rem !important; }

.padding-left-1 {
  padding-left: 1rem !important; }

.margin-horizontal-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.padding-horizontal-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.margin-vertical-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.padding-vertical-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.margin-2 {
  margin-top: 2rem !important;
  margin-right: 2rem !important;
  margin-bottom: 2rem !important;
  margin-left: 2rem !important; }

.padding-2 {
  padding-top: 2rem !important;
  padding-right: 2rem !important;
  padding-bottom: 2rem !important;
  padding-left: 2rem !important; }

.margin-top-2 {
  margin-top: 2rem !important; }

.padding-top-2 {
  padding-top: 2rem !important; }

.margin-right-2 {
  margin-right: 2rem !important; }

.padding-right-2 {
  padding-right: 2rem !important; }

.margin-bottom-2 {
  margin-bottom: 2rem !important; }

.padding-bottom-2 {
  padding-bottom: 2rem !important; }

.margin-left-2 {
  margin-left: 2rem !important; }

.padding-left-2 {
  padding-left: 2rem !important; }

.margin-horizontal-2 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.padding-horizontal-2 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.margin-vertical-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.padding-vertical-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.margin-3 {
  margin-top: 3rem !important;
  margin-right: 3rem !important;
  margin-bottom: 3rem !important;
  margin-left: 3rem !important; }

.padding-3 {
  padding-top: 3rem !important;
  padding-right: 3rem !important;
  padding-bottom: 3rem !important;
  padding-left: 3rem !important; }

.margin-top-3 {
  margin-top: 3rem !important; }

.padding-top-3 {
  padding-top: 3rem !important; }

.margin-right-3 {
  margin-right: 3rem !important; }

.padding-right-3 {
  padding-right: 3rem !important; }

.margin-bottom-3 {
  margin-bottom: 3rem !important; }

.padding-bottom-3 {
  padding-bottom: 3rem !important; }

.margin-left-3 {
  margin-left: 3rem !important; }

.padding-left-3 {
  padding-left: 3rem !important; }

.margin-horizontal-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.padding-horizontal-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.margin-vertical-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.padding-vertical-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

body {
  hyphens: auto; }

.logo img {
  width: 45px;
  height: 70px;
  float: left; }

.logo span {
  color: #fefefe;
  display: block;
  float: left;
  margin: 0 0 0 0.625rem; }

.logo .site-title {
  font-weight: 300; }
  .logo .site-title b {
    font-weight: 700;
    display: block; }

iframe {
  border: none; }

.width-100 {
  width: 100%; }

.margin-top-0 {
  margin-top: 0rem; }

.margin-top-1 {
  margin-top: 1rem; }

.margin-top-2 {
  margin-top: 2rem; }

.margin-top-3 {
  margin-top: 3rem; }

.margin-bottom-1 {
  margin-bottom: 1rem; }

.margin-bottom-2 {
  margin-bottom: 2rem; }

.padding-left-1 {
  padding-left: 1rem; }

article ul, .infobox ul {
  list-style: none; }
  article ul li, .infobox ul li {
    display: flex;
    margin-bottom: 0.3125rem; }
    article ul li:before, .infobox ul li:before {
      content: '»';
      margin: 0 0.625rem 0 0; }

article ol, .infobox ol {
  list-style-position: inside; }

.download-list li::before {
  display: none; }

.download-list li a {
  font-weight: 600;
  color: #3F3F3F; }
  .download-list li a:hover {
    color: #5171B0; }
  .download-list li a span {
    font-size: 0.875rem;
    color: #6E6E6E;
    display: block; }

article h1, article h2, article h3, article h4 {
  margin: 0 0 1rem 0; }

.subhead {
  font-size: 1.125rem;
  text-transform: uppercase;
  color: #4C4C4C;
  display: block;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  letter-spacing: 0.0625rem;
  margin-bottom: 1rem; }

.highlight-paragraph {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.875rem;
  margin: 0 0 1.875rem 0; }
  @media screen and (max-width: 49.9375em) {
    .highlight-paragraph {
      font-size: 1.0625rem; } }

.blockquote-container {
  background-origin: content-box;
  background-size: cover;
  width: 100%;
  height: 300px;
  position: relative; }
  .blockquote-container blockquote {
    width: 66.66667%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    @media print, screen and (min-width: 40em) {
      .blockquote-container blockquote {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
    .blockquote-container blockquote:last-child:not(:first-child) {
      float: right; }
    .blockquote-container blockquote p {
      font-size: 2rem;
      font-family: "Prata", serif;
      color: #fefefe; }
      .blockquote-container blockquote p::before {
        content: ' „ ' ' ';
        color: #fefefe;
        font-family: Georgia, serif; }
      .blockquote-container blockquote p::after {
        content: ' ' ' “ ';
        color: #fefefe;
        font-family: Georgia, serif; }
    .blockquote-container blockquote cite {
      color: #fefefe;
      text-transform: uppercase;
      font-weight: 600; }

figure {
  position: relative;
  overflow: hidden;
  transition: 0.3s; }
  figure a {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    transition: 0.3s;
    opacity: 0;
    background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(25, 34, 51, 0) 0%, rgba(25, 34, 51, 0.9) 75%) repeat scroll 0 0;
    color: #fefefe; }
    figure a span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.125rem;
      top: 40%;
      transform: translate(-50%, 0%);
      transition: 0.3s; }
  figure img {
    width: 100%;
    max-width: none; }
  figure:hover a {
    opacity: 1; }
    figure:hover a span {
      transform: translate(-50%, -50%);
      color: #fefefe; }

.figure-title {
  padding: 1rem 1rem;
  display: block;
  background: #fefefe;
  font-weight: 600;
  color: #757575; }

.arrow-circle {
  width: 25px;
  height: 25px;
  display: flex;
  border: 2px solid #ccc;
  border-radius: 100%;
  float: right;
  position: relative;
  justify-content: center;
  align-items: center; }
  .arrow-circle:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 8px;
    border-color: transparent transparent transparent #ccc;
    display: inline-block;
    margin: 2px 0 0 2px; }

.readmore-teaser figcaption {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1; }

.readmore-teaser__image {
  width: 100%; }

.btn, .btn-white, .btn-blue {
  border: 1px solid #6E6E6E;
  padding: 0.625rem 1.25rem;
  color: #6E6E6E;
  border-radius: 25px;
  display: inline-block;
  margin: 0.625rem 0 0 0;
  transition: .3s;
  text-decoration: none;
  cursor: pointer; }

.btn-white {
  border: 1px solid #fefefe;
  color: #fefefe; }
  .btn-white:focus, .btn-white:hover {
    background: #fefefe;
    color: #4C4C4C; }

.btn-blue {
  border: 1px solid #1779ba;
  background: #1779ba;
  color: #fefefe; }
  .btn-blue:focus, .btn-blue:hover {
    background: transparent;
    color: #1779ba; }

.btn-primary {
  color: #fefefe;
  border: none; }

@media screen and (max-width: 49.9375em) {
  .table-responsive tr {
    display: flex;
    flex-flow: column wrap; } }

.newsfeed {
  display: table;
  width: 100%; }

.blog.police {
  padding: 3rem 0; }
  .blog.police .page-header,
  .blog.police .moduletable {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .blog.police .page-header,
      .blog.police .moduletable {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }

.newsfeed-item {
  border: 1px solid #D9D9D9;
  border-top: 0; }
  .newsfeed-item:nth-child(1) {
    border-top: 1px solid #D9D9D9; }
  .newsfeed-item:nth-child(2n) {
    background: rgba(254, 254, 254, 0.8); }
  .newsfeed-item h2 {
    font-size: 1.375rem;
    margin: 0 0 0.3125rem 0; }
  .newsfeed-item p:last-child {
    margin: 0; }
  .newsfeed-item span {
    font-weight: 700;
    color: #6E6E6E; }
  .newsfeed-item .newsfeed__date {
    width: 10rem; }
  .newsfeed-item .newsfeed-date-block,
  .newsfeed-item .newsfeed-content-block {
    padding: 1.25rem 0.9375rem; }
    @media print, screen and (min-width: 40em) {
      .newsfeed-item .newsfeed-date-block,
      .newsfeed-item .newsfeed-content-block {
        display: table-cell;
        padding: 1.875rem 1.5625rem; } }

.alert-rss {
  padding: 20px 25px;
  border: 1px solid #D9D9D9;
  margin: 0 0 25px 0;
  width: 100%; }
  .alert-rss p {
    margin: 0; }

.slick-next:before, .slick-prev:before {
  color: #192233 !important; }

.allris__submenu {
  margin: 1rem 0 2rem 0; }

.allris__submenu__link {
  color: #ffffff;
  text-decoration: none; }
  .allris__submenu__link:hover {
    color: #ffffff; }

.allris__submenu__item {
  display: block;
  float: left;
  height: 2rem;
  background-color: #1779ba;
  text-align: center;
  color: white;
  margin-right: 1rem;
  margin: 0 1rem 1rem 0;
  padding: 0.2rem 1rem;
  box-sizing: border-box;
  cursor: pointer; }
  .allris__submenu__item:hover {
    background-color: #2196e4; }
  .allris__submenu__item--active {
    background-color: #2196e4 !important; }
  .allris__submenu__item--intern {
    background-color: #417090; }

.top-bar .row {
  position: relative; }

.top-bar .logo {
  position: absolute;
  top: 0;
  left: 1.25rem;
  z-index: 2;
  font-size: 1rem; }

.menu {
  float: left; }

.search-trigger {
  position: relative;
  display: block;
  width: 26px;
  height: 44px;
  overflow: hidden;
  white-space: nowrap;
  /* hide text */
  color: transparent;
  z-index: 1;
  font-size: 0;
  float: right;
  margin: 0 0 0 0.9375rem; }
  @media screen and (max-width: 49.9375em) {
    .search-trigger {
      display: none !important; } }
  .search-trigger::before, .search-trigger::after {
    /* search icon */
    content: '';
    position: absolute;
    transition: opacity .3s;
    /* Force Hardware Acceleration in WebKit */
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }
  .search-trigger::before {
    /* lens */
    top: 6px;
    left: 6px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #fefefe; }
  .search-trigger::after {
    /* handle */
    height: 2px;
    width: 8px;
    background: #fefefe;
    bottom: 19px;
    right: 0;
    transform: rotate(45deg); }
  .search-trigger span {
    /* container for the X icon */
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; }
  .search-trigger span::before, .search-trigger span::after {
    /* close icon */
    content: '';
    position: absolute;
    display: inline-block;
    height: 2px;
    width: 22px;
    top: 50%;
    margin-top: -2px;
    left: 50%;
    margin-left: -11px;
    background: #fefefe;
    opacity: 0;
    /* Force Hardware Acceleration in WebKit */
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: opacity .3s, transform .3s; }
  .search-trigger span::before {
    transform: rotate(45deg); }
  .search-trigger span::after {
    transform: rotate(-45deg); }
  .search-trigger.is-visible::before, .search-trigger.is-visible::after {
    /* hide search icon */
    opacity: 0; }
  .search-trigger.is-visible span::before, .search-trigger.is-visible span::after {
    /* show close icon */
    opacity: 1; }
  .search-trigger.is-visible span::before {
    transform: rotate(135deg); }
  .search-trigger.is-visible span::after {
    transform: rotate(45deg); }

.nav-trigger {
  /* vertically align its content */
  height: 100%;
  padding: 0 0 0 1.25rem;
  margin: 0 0 0 1.25rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #fefefe;
  font-weight: bold;
  right: 0;
  border-left: 1px solid rgba(254, 254, 254, 0.2);
  display: table;
  float: right; }
  .nav-trigger span {
    /* vertically align inside parent element */
    display: table-cell;
    vertical-align: middle;
    color: #fefefe; }
  .nav-trigger em, .nav-trigger em::after, .nav-trigger em::before {
    /* this is the menu icon */
    display: block;
    position: relative;
    height: 2px;
    width: 22px;
    background-color: #fefefe;
    backface-visibility: hidden; }
  .nav-trigger em {
    /* this is the menu central line */
    margin: 6px auto 14px;
    transition: background-color .2s; }
  .nav-trigger em::before, .nav-trigger em::after {
    position: absolute;
    content: '';
    left: 0;
    transition: transform .2s; }
  .nav-trigger em::before {
    /* this is the menu icon top line */
    transform: translateY(-6px); }
  .nav-trigger em::after {
    /* this is the menu icon bottom line */
    transform: translateY(6px); }
  @media screen and (min-width: 73.4375em) {
    .nav-trigger {
      display: none; } }

.nav-trigger.is-visible em {
  /* transform menu icon into a 'X' icon */
  background-color: rgba(254, 254, 254, 0); }

.nav-trigger.is-visible em::before {
  /* rotate top line */
  transform: rotate(-45deg); }

.nav-trigger.is-visible em::after {
  /* rotate bottom line */
  transform: rotate(45deg); }

/*
.search {
  background: $white;
  opacity: 0;
  visibility: hidden;
  height: 0;
  position: absolute;
  top: 119px;
  left: 0;
  width: 100%;
  transition: .3s;
  z-index: 0;

  &.is-visible {
    opacity: 1;
    visibility: visible;
    height: auto;
  }

  label {
    display: none;
  }

  form {
    @include grid-column;
    float: none;
    position: relative;

    input[type='text'] {
      border-radius: 0;
      border: none;
      background: $white;
      padding: rem-calc(30px) 0;
      box-shadow: none;
      margin: 0;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      margin: 0;
      border-radius: 0;
      border: none;
      background: $primary-color;
      color: $white;
    }
  }
}
*/
.post-view {
  padding: 3.125rem 0; }
  .post-view h1 {
    font-size: 1.875rem;
    margin: 0 0 1.25rem 0; }
    @media print, screen and (min-width: 40em) {
      .post-view h1 {
        font-size: 2.25rem;
        margin: 0 0 2.1875rem 0; } }
  .post-view figure {
    margin: 0 0 1.25rem 0; }
    .post-view figure img {
      width: 100%; }

.card {
  background: #fefefe; }

.card-head {
  font-size: 1.125rem;
  margin: 0 0 0.9375rem 0; }

.card-time {
  color: #7D7D7D;
  font-size: 0.75rem;
  margin: 0 0 0.3125rem 0;
  display: block; }

.card-label {
  color: #7D7D7D;
  font-size: 0.875rem;
  margin: 0 0 0.3125rem 0;
  display: inline-block; }

.card-desc {
  margin: 0 0 1.875rem 0; }

.card-link {
  color: #3F3F3F; }
  .card-link:before {
    content: '»';
    margin: 0 0.3125rem 0 0; }

.infobox {
  padding: 1.25rem;
  background: #fefefe; }

.teaser-box-one, .teaser-box-two, .teaser-box-six, .teaser-box-seven {
  float: left;
  width: 33.3333%;
  height: 330px; }

.teaser-box {
  padding: 5px; }
  @media screen and (max-width: 49.9375em) {
    .teaser-box {
      padding: 0.625rem 1.25rem; } }
  .teaser-box figure {
    height: 100%;
    background-size: cover; }
  .teaser-box figcaption {
    position: absolute;
    width: 100%;
    bottom: 0; }

.teaser-box-three {
  box-sizing: border-box;
  float: right;
  width: 33.3333%;
  height: 660px; }

.teaser-box-four, .teaser-box-eight {
  box-sizing: border-box;
  float: left;
  width: 66.6667%;
  height: 330px; }

@media screen and (max-width: 49.9375em) {
  .teaser-box-one,
  .teaser-box-two,
  .teaser-box-three,
  .teaser-box-four,
  .teaser-box-six,
  .teaser-box-seven,
  .teaser-box-eight {
    width: 50%;
    height: 350px; } }

.abstand-all-null .moduletable .row--koppa {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  position: relative; }
  .abstand-all-null .moduletable .row--koppa:after {
    content: '';
    display: block;
    width: calc(100% - 32px);
    border-bottom: 1px solid #D9D9D9;
    position: relative;
    top: 16px;
    left: 16px; }
  @media print, screen and (min-width: 40em) {
    .abstand-all-null .moduletable .row--koppa {
      padding-bottom: 2.5rem;
      margin-bottom: 2.5rem; }
      .abstand-all-null .moduletable .row--koppa:after {
        top: 40px; } }

.abstand-all-null .moduletable:nth-last-child(2) .row--koppa {
  padding-bottom: 0;
  margin-bottom: 0; }
  .abstand-all-null .moduletable:nth-last-child(2) .row--koppa:after {
    display: none; }

.card--koppa {
  margin: 0; }
  .card--koppa .card-head {
    padding: 1rem;
    margin: 0;
    display: block;
    font-weight: 600;
    font-family: "Lato", sans-serif; }
    .card--koppa .card-head a {
      text-decoration: none;
      color: #757575; }
  .card--koppa > a {
    display: block; }

@media print, screen and (min-width: 40em) {
  .card--koppa-small > a {
    position: relative;
    height: 270px;
    overflow: hidden; }
    .card--koppa-small > a img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%); } }

.card--koppa-large {
  margin-bottom: 1.25rem; }
  .card--koppa-large > a {
    display: block; }
    .card--koppa-large > a img {
      width: 100% !important; }

.cookie-disclaimer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #000;
  padding: 1.5625rem 0;
  display: none; }
  .cookie-disclaimer .is-visible {
    display: block; }
  .cookie-disclaimer .column, .cookie-disclaimer .columns {
    display: flex;
    align-items: flex-start; }
  .cookie-disclaimer p {
    color: #fefefe;
    flex: 1 1 0;
    margin: 0 2.1875rem 0 0;
    font-size: 0.875rem; }
  .cookie-disclaimer .cookie-disclaimer-button {
    color: #fefefe;
    flex: 0 0 auto;
    max-width: 100%;
    font-size: 0.875rem; }

.content_banner {
  background-color: #004C65;
  background-size: cover;
  background-position: center center; }
  .content_banner h1,
  .content_banner p {
    color: #eee; }
  .content_banner.small {
    height: 20rem; }
    .content_banner.small > div {
      padding-top: 3rem; }
  .content_banner.medium {
    height: 30rem; }
    .content_banner.medium > div {
      padding-top: 5rem; }
      @media print, screen and (min-width: 40em) {
        .content_banner.medium > div {
          padding-top: 10rem; } }
  .content_banner.large {
    height: 40rem; }
    .content_banner.large > div {
      padding-top: 7rem; }
      @media print, screen and (min-width: 40em) {
        .content_banner.large > div {
          padding-top: 15rem; } }
  .content_banner.top {
    background-position: center top; }
  .content_banner.center {
    background-position: center center; }
  .content_banner.bottom {
    background-position: center bottom; }
  .content_banner .overlay0 {
    width: 100%;
    height: 100%; }
  .content_banner .overlay20 {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); }
  .content_banner .overlay40 {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); }

.content_zwischenheadline h3 {
  margin: 0; }

.content_zwischenheadline h4 {
  color: #3F3F3F; }

.content_news {
  display: block;
  background-color: white;
  padding: 1rem;
  -webkit-box-shadow: 1px 1px 4px 0 #D4D4D4;
  box-shadow: 0 1px 4px 0 #D4D4D4;
  transition: 0.2s;
  position: relative;
  height: 32rem;
  text-decoration: none; }
  .content_news:hover {
    -webkit-box-shadow: 2px 2px 8px 0 #D4D4D4;
    box-shadow: 0 4px 8px 0 #D4D4D4; }
  .content_news h4 {
    color: #333;
    font-size: 1.5rem; }
  .content_news h5 {
    font-family: "Lato", sans-serif;
    color: #333;
    font-size: 0.8rem; }
  .content_news img {
    margin-bottom: 0.5rem;
    width: 100%; }
  .content_news p {
    font-family: "Lato", sans-serif;
    min-height: 7rem;
    color: #3F3F3F;
    margin-bottom: 0.5rem; }
  .content_news .readmore {
    font-family: "Lato", sans-serif;
    color: #333;
    font-weight: bold;
    position: absolute;
    bottom: 1rem; }

@media screen and (max-width: 39.9375em) {
  #content h1 {
    font-size: 1.875rem; } }

#content img {
  max-width: none;
  width: 100%; }

.download {
  display: flex;
  align-items: center;
  margin-bottom: 1rem; }
  .download::before {
    content: '»';
    margin: 0 0.3125rem 0 0; }

#content img.download__icon {
  width: 1.3rem;
  max-width: 100%;
  margin-right: 1rem; }

.download-row {
  border-bottom: 1px solid #cacaca;
  padding-bottom: 3rem; }
  .download-row:last-child {
    border-bottom: none;
    padding-bottom: 0; }

.event__row {
  display: flex;
  flex: 1;
  flex-wrap: wrap; }

.event__wrapper {
  display: flex; }

.event__box {
  height: auto !important;
  align-self: stretch;
  flex: 1; }

.event__image__wrapper {
  padding-top: 100%;
  position: relative;
  width: 100%;
  margin-bottom: 1rem; }

.event__image__image {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center top;
  background-color: #DEDEDE; }

.event__title {
  text-overflow: ellipsis; }

#blockrandom {
  cursor: pointer;
  background: #fefefe; }

.event-filter {
  background: #fefefe;
  padding: 1.5rem;
  margin-bottom: 1.875rem; }
  .event-filter__row {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto; }
    .event-filter__row::before, .event-filter__row::after {
      display: table;
      content: ' '; }
    .event-filter__row::after {
      clear: both; }
  .event-filter__label {
    display: block;
    font-size: 1.125rem;
    margin: 0 0 0.5rem 0; }
  .event-filter input,
  .event-filter select,
  .event-filter .filter__date-col .inputbox,
  .filter__date-col .event-filter .inputbox {
    margin: 0; }
  .event-filter__date-col, .event-filter__location, .event-filter__search {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    margin: 0; }
    @media print, screen and (min-width: 40em) {
      .event-filter__date-col, .event-filter__location, .event-filter__search {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
    .event-filter__date-col:last-child:not(:first-child), .event-filter__location:last-child:not(:first-child), .event-filter__search:last-child:not(:first-child) {
      float: right; }
  .event-filter__search .button {
    margin-left: 0.5rem; }
  .event-filter__date-col label {
    margin-right: 0.5rem; }
  .event-filter__search-group {
    display: flex; }
  @media print, screen and (min-width: 40em) {
    .event-filter__date-col, .event-filter__location {
      width: 50%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    .event-filter__date-col, .event-filter__location {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 40em) {
      .event-filter__date-col:last-child:not(:first-child), .event-filter__location:last-child:not(:first-child) {
        float: right; } }
  @media print, screen and (min-width: 64em) {
    .event-filter__date-col, .event-filter__location, .event-filter__search {
      width: 25%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  @media print, screen and (min-width: 64em) and (min-width: 40em) {
    .event-filter__date-col, .event-filter__location, .event-filter__search {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 64em) {
      .event-filter__date-col:last-child:not(:first-child), .event-filter__location:last-child:not(:first-child), .event-filter__search:last-child:not(:first-child) {
        float: right; }
    .event-filter__search {
      width: 50%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  @media print, screen and (min-width: 64em) and (min-width: 40em) {
    .event-filter__search {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 64em) {
      .event-filter__search:last-child:not(:first-child) {
        float: right; } }

.ggvz-toolbar .ggvz-toolbar-box {
  background: #fefefe;
  padding: 0 1rem;
  margin-bottom: 16px; }
  @media print, screen and (min-width: 40em) {
    .ggvz-toolbar .ggvz-toolbar-box {
      display: flex;
      justify-content: space-between; } }
  .ggvz-toolbar .ggvz-toolbar-box .profile-edit {
    text-decoration: none;
    display: inline-block;
    padding: 1rem 0; }

.ggvz-toolbar .menu_menu {
  margin: 0; }
  .ggvz-toolbar .menu_menu li {
    display: inline-block;
    margin-right: 16px; }
    @media screen and (max-width: 39.9375em) {
      .ggvz-toolbar .menu_menu li {
        display: block; } }
    .ggvz-toolbar .menu_menu li a {
      text-decoration: none;
      display: inline-block;
      padding: 1rem 0;
      border-bottom: 2px solid transparent; }
    .ggvz-toolbar .menu_menu li:hover a, .ggvz-toolbar .menu_menu li.current a {
      border-color: #5171B0; }

#gastgeber #login-form {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  #gastgeber #login-form .userdata {
    width: 100%;
    background: #fefefe;
    padding: 1.5rem;
    margin-bottom: 1.875rem; }
    #gastgeber #login-form .userdata .passwort-reset {
      margin-top: 0.9375rem; }

#gastgeber .login-greeting {
  margin-right: 1rem; }

#gastgeber .logout-button .btn, #gastgeber .logout-button .btn-white, #gastgeber .logout-button .btn-blue {
  margin: 0; }

#gastgeber .btn.jmodedit, #gastgeber .jmodedit.btn-white, #gastgeber .jmodedit.btn-blue {
  display: none !important; }

#gastgeber .oho + .login,
#gastgeber .oho + .logout {
  display: none; }

#gastgeber .btn-primary {
  background: #1779ba;
  color: #fefefe; }

#gastgeber .btn, #gastgeber .btn-white, #gastgeber .btn-blue {
  line-height: 1rem; }

#reservation_calculator {
  margin-top: 1.875rem; }
  #reservation_calculator .kc_calc_advance h3,
  #reservation_calculator .kc_calc_sum h3 {
    font-family: "Lato", sans-serif;
    color: #3F3F3F;
    font-size: 1rem; }

#date_fromkropen,
#date_tokropen {
  margin: 0 0 1rem 0.5rem;
  width: 70px; }
  #date_fromkropen .icon-calendar,
  #date_tokropen .icon-calendar {
    background: url("/media/com_koparent/icons/cal_small.svg");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    width: 24px;
    height: 24px; }
    #date_fromkropen .icon-calendar:before,
    #date_tokropen .icon-calendar:before {
      content: ''; }

.table.table-striped.kc_calc_mainTable td {
  padding: 10px 16px; }

.kc_calc_header {
  text-align: left;
  padding: 10px 16px; }
  .kc_calc_header h3 {
    margin-bottom: 0; }

#map.small {
  height: 20rem; }

#map.medium {
  height: 30rem; }

#map.large {
  height: 40rem; }

.service--fadeout {
  opacity: 0;
  transition: opacity 0.2s; }

.service--hidden {
  display: none;
  height: 0 !important;
  /*transition: 0.2s;*/
  overflow: hidden; }

.min-height {
  min-height: 30rem; }

.serviceitem {
  /*transition: 0.2s;*/
  font-family: "Lato", sans-serif;
  margin-bottom: 0.5rem; }

.service--fadeout.service_K {
  color: green; }

.service__indexbar {
  margin: 1rem 0 2rem 0; }

.service__indexbar__item {
  display: block;
  float: left;
  width: 2rem;
  height: 2rem;
  background-color: #1779ba;
  text-align: center;
  color: white;
  margin-right: 1rem;
  margin: 0 1rem 1rem 0;
  padding: 0.2rem 0;
  box-sizing: border-box;
  cursor: pointer; }

.service__indexbar__item:hover {
  background-color: #2196e4; }

/* SIDEBOX */
.service__sidebox {
  padding: 1rem;
  background-color: white; }
  .service__sidebox__content {
    padding: 1rem; }
  .service__sidebox table {
    width: 100% !important; }

.openerBildGroup .column, .openerBildGroup .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .openerBildGroup .column, .openerBildGroup .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .openerBildGroup .column:last-child:not(:first-child), .openerBildGroup .columns:last-child:not(:first-child) {
    float: right; }
  @media print, screen and (min-width: 50em) {
    .openerBildGroup .column, .openerBildGroup .columns {
      width: 33.33333%;
      float: left;
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  @media print, screen and (min-width: 50em) and (min-width: 40em) {
    .openerBildGroup .column, .openerBildGroup .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 50em) {
      .openerBildGroup .column:last-child:not(:first-child), .openerBildGroup .columns:last-child:not(:first-child) {
        float: right; } }

.openerBild {
  background-color: white;
  padding: 1rem;
  height: 30rem;
  position: relative;
  text-decoration: none;
  display: block; }
  .openerBild p {
    color: #3f3f3f; }
  .openerBild .link {
    position: absolute;
    bottom: 1rem; }
    .openerBild .link::before {
      content: "»";
      margin: 0 0.3125rem 0 0; }

.openerBild__image {
  margin-bottom: 1rem; }

.infobox {
  background: #fefefe;
  padding: 1.5625rem; }
  @media print, screen and (min-width: 64em) {
    .infobox {
      padding: 2.1875rem; } }
  .infobox h3 {
    font-size: 1.5rem;
    color: #3F3F3F; }

.content-iframe iframe {
  width: 100% !important; }

.breadcrumb-container {
  width: 100%;
  padding: 0.3125rem 0;
  background: #fefefe;
  border-bottom: 1px solid #D9D9D9; }

.breadcrumb {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  align-items: stretch;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  /*
  li + li::before {
    content: "→";
  }
  */ }
  .breadcrumb::before, .breadcrumb::after {
    display: table;
    content: ' '; }
  .breadcrumb::after {
    clear: both; }
  .breadcrumb ul {
    width: 100%;
    float: left;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start;
    margin: 0; }
    @media print, screen and (min-width: 40em) {
      .breadcrumb ul {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
    .breadcrumb ul:last-child:not(:first-child) {
      float: right; }
  .breadcrumb li {
    align-items: center;
    display: flex;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.25 18.333'%3E%3Cpath d='M9.648 8.358L1.553.266c-.355-.354-.93-.354-1.287 0-.355.354-.355.93 0 1.284L7.72 9 .266 16.45c-.355.354-.355.93 0 1.285.355.354.932.354 1.287 0L9.65 9.643c.348-.35.348-.936-.002-1.285z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 18px;
    background-size: 5px; }
    .breadcrumb li:first-child a span {
      padding: 0.5em 1em 0.5em 0; }
    .breadcrumb li:last-child {
      background: none; }
  .breadcrumb span {
    color: #7a7a7a;
    display: flex;
    justify-content: center;
    padding: 0.5em 0.9375rem 0.5em 0.625rem; }
  .breadcrumb a {
    text-decoration: none; }
    .breadcrumb a:hover {
      color: #1779ba; }

.header-teaser-container {
  height: 80vh; }
  .header-teaser-container .row {
    height: 100%;
    position: relative; }
  .header-teaser-container.small {
    height: 300px; }
  .header-teaser-container.auto-height {
    height: auto; }

.header-teaser-image {
  background-size: cover;
  width: 100%;
  height: 100%; }
  .header-teaser-image .row {
    height: 100%;
    position: relative; }
  .auto-height .header-teaser-image {
    padding: 2.1875rem 0; }

.header-teaser-para {
  font-size: 1.25rem;
  margin: 0; }

.header-teaser-content {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  background: rgba(10, 10, 10, 0.3);
  width: calc(100% - 40px);
  color: #fefefe;
  padding: 1.5625rem 1.5625rem; }
  @media screen and (min-width: 73.4375em) {
    .header-teaser-content {
      width: 60%; } }
  .auto-height .header-teaser-content {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0);
    width: 100%;
    padding: 1.5625rem 1.5625rem; }
    @media screen and (max-width: 49.9375em) {
      .auto-height .header-teaser-content {
        left: 0; } }

.header-teaser-head {
  color: #fefefe; }

#jcemediabox-popup-closelink {
  width: auto;
  text-decoration: none; }
  #jcemediabox-popup-closelink:hover {
    text-decoration: underline; }

.main-wrapper {
  transform: translateZ(0);
  will-change: transform;
  transition: transform 0.3s ease 0s;
  overflow: hidden; }
  .main-wrapper.is-visible {
    transform: translateX(-310px); }

.mobile-navigation {
  background: #141b29;
  height: 100%;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease 0s;
  width: 310px;
  z-index: 1; }
  .mobile-navigation.is-visible {
    transform: translateX(0); }
  .mobile-navigation a, .mobile-navigation span {
    display: block;
    padding: 1.5625rem 1.25rem;
    color: #fefefe;
    font-size: 1.0625rem;
    background: #141b29;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #3a3f40;
    transform: translateZ(0);
    will-change: transform, opacity;
    transition: transform .3s, opacity .3s;
    text-decoration: none; }
  .mobile-navigation .drilldown {
    width: 100%; }
  .mobile-navigation .is-drilldown-submenu a, .mobile-navigation .is-drilldown-submenu span {
    padding: 1.5625rem 1.25rem; }
  .mobile-navigation .js-drilldown-back a {
    padding: 1.5625rem 1.25rem; }

header .menu {
  display: none; }
  @media screen and (min-width: 73.4375em) {
    header .menu {
      display: flex !important; } }
  header .menu li {
    font-size: 0.875rem; }
    header .menu li.parent {
      position: relative;
      transition: .3s;
      margin: 0 0.9375rem 0 0; }
    header .menu li:nth-last-child(2) {
      margin: 0; }
      header .menu li:nth-last-child(2) > ul .submenu.is-dropdown-submenu {
        right: calc(100% - 10px);
        left: inherit; }
    header .menu li:hover > ul,
    header .menu li .is-active > ul,
    header .menu li:hover .submenu.first-sub {
      opacity: 1;
      visibility: visible; }
    header .menu li .search-trigger:after {
      right: 3px; }
  header .menu a, header .menu span {
    color: #fefefe;
    position: relative;
    z-index: 2;
    display: block;
    line-height: 1;
    text-decoration: none;
    padding: 0.7rem 1rem; }
  header .menu .submenu {
    position: absolute;
    top: 0;
    left: -10px;
    padding: 0.625rem;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    z-index: 1;
    margin: 0;
    white-space: nowrap;
    background: transparent; }
    header .menu .submenu > li {
      display: block;
      margin: 0; }
      header .menu .submenu > li > a, header .menu .submenu > li > span {
        background: #192233; }
    header .menu .submenu.first-sub {
      padding: 64px 0.625rem 0 0.625rem; }
      header .menu .submenu.first-sub > li:hover > a,
      header .menu .submenu.first-sub > li.is-active > a,
      header .menu .submenu.first-sub > li:hover > span,
      header .menu .submenu.first-sub > li.is-active > span {
        background: #1e293d;
        color: #fefefe; }
    header .menu .submenu .is-dropdown-submenu {
      left: calc(100% - 10px);
      top: -10px; }
      header .menu .submenu .is-dropdown-submenu > li:hover > a {
        background: #1e293d; }
      header .menu .submenu .is-dropdown-submenu > li > a {
        background: #111722; }

.header-top {
  background: #192233; }

.meta-menu {
  margin: 0 0.9375rem 0 0;
  padding: 0.3125rem 0;
  float: right; }
  .meta-menu li {
    display: inline;
    margin: 0 0.625rem;
    font-size: 0.8125rem;
    position: relative; }
    .meta-menu li:last-child {
      margin-right: 0; }
    .meta-menu li:hover > ul,
    .meta-menu li .is-active > ul,
    .meta-menu li:hover .submenu.first-sub {
      opacity: 1;
      visibility: visible; }
    .meta-menu li a {
      color: rgba(254, 254, 254, 0.8);
      text-decoration: none; }
    .meta-menu li .submenu {
      position: absolute;
      top: 100%;
      left: 0;
      background: #080b11;
      display: none;
      z-index: 5;
      margin: 0;
      white-space: nowrap;
      padding-top: 0.3125rem; }
      .meta-menu li .submenu > li {
        display: block;
        padding: 0;
        margin: 0; }
        .meta-menu li .submenu > li > a, .meta-menu li .submenu > li span {
          line-height: 1;
          text-decoration: none;
          display: block;
          padding: 0.7rem 1rem; }
        .meta-menu li .submenu > li:hover > a,
        .meta-menu li .submenu > li.is-active > a {
          background: #1e293d;
          color: #fefefe; }
      .meta-menu li .submenu.first-sub {
        padding: 64px 0 0 0; }
        .meta-menu li .submenu.first-sub > li:hover > a,
        .meta-menu li .submenu.first-sub > li.is-active > a {
          background: #1e293d;
          color: #fefefe; }
      .meta-menu li .submenu .is-dropdown-submenu {
        left: 100%;
        background: #141b29; }
        .meta-menu li .submenu .is-dropdown-submenu > li:hover > a {
          background: #1e293d; }

.news__wrapper {
  margin-bottom: 3rem !important; }

.news__date {
  margin: 0 0 2rem 0;
  padding: 0; }

.section-newsletter {
  background-color: white;
  padding: 2.1875rem; }
  .section-newsletter .column, .section-newsletter .columns, .section-newsletter .moduletable {
    float: none; }
  @media print, screen and (min-width: 40em) {
    .section-newsletter .newsletter-form-wrapper {
      display: flex; } }
  .section-newsletter .newsletter-form-wrapper label {
    width: 100px;
    font-size: 1.125rem; }
  .section-newsletter .newsletter-form-wrapper .newsletter-input {
    width: 100%;
    height: auto; }
    @media print, screen and (min-width: 40em) {
      .section-newsletter .newsletter-form-wrapper .newsletter-input {
        margin: 0 1.25rem 0 0; } }
  @media print, screen and (min-width: 40em) {
    .section-newsletter .newsletter-form-wrapper .send-button {
      margin: 0; } }
  .section-newsletter .privacy-accepted-wrapper {
    text-align: right; }
    .section-newsletter .privacy-accepted-wrapper input {
      margin: 1rem 0 0 1rem; }

footer {
  background: #192233;
  padding: 2.8125rem 0 0 0; }

.footer-nav-column {
  font-size: 0.875rem;
  list-style: none;
  float: left;
  margin-right: 1rem;
  border-left: 1px solid #888;
  padding-left: 1rem;
  word-break: break-word;
  hyphens: auto;
  width: 100%; }
  @media print, screen and (min-width: 40em) {
    .footer-nav-column {
      width: 30%; } }
  @media screen and (min-width: 40em) and (max-width: 49.9375em) {
    .footer-nav-column:nth-child(4) {
      clear: both; } }
  @media print, screen and (min-width: 64em) {
    .footer-nav-column {
      width: 18%; } }
  .footer-nav-column a, .footer-nav-column span {
    color: #fefefe;
    text-decoration: none; }
  .footer-nav-column li {
    list-style: none; }
    .footer-nav-column li:last-child {
      margin: 0 0 0 0; }
  .footer-nav-column .parent:first-child {
    font-weight: 700;
    margin: 0 0 1.875rem 0; }
    .footer-nav-column .parent:first-child li {
      margin: 0 0 1.875rem 0; }
  .footer-nav-column .nav-child {
    font-weight: 400;
    margin: 0; }
    .footer-nav-column .nav-child li {
      margin: 0 0 0.625rem 0; }
    .footer-nav-column .nav-child a, .footer-nav-column .nav-child span {
      color: rgba(254, 254, 254, 0.8); }

.footer__first {
  padding-bottom: 1rem; }

.footer__second {
  background-color: #080b11;
  padding-top: 2rem;
  padding-bottom: 2rem; }

.footer-meta-menu {
  float: right; }
  .footer-meta-menu li {
    display: inline-block;
    margin: 0 0.9375rem 0 0; }
    .footer-meta-menu li:last-child {
      margin: 0; }
    .footer-meta-menu li:hover a {
      text-decoration: underline; }
    .footer-meta-menu li a {
      color: #fefefe;
      text-decoration: none; }

footer a.search-trigger {
  display: none; }

.checkbox.inline {
  display: inline-block;
  margin: 0 1rem; }
  .checkbox.inline:first-child {
    margin-left: 0; }
  .checkbox.inline:last-child {
    margin-right: 0; }

.bfFormDiv {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  padding: 3.125rem 0; }

.bfQuickMode {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0; }
  @media print, screen and (min-width: 40em) {
    .bfQuickMode {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.login-form {
  margin: 0 0.9375rem;
  background: #fefefe;
  padding: 1.5rem;
  margin-bottom: 1.875rem; }

.login-pass {
  margin: 0 0.9375rem; }

#system-message {
  margin: 0 0.9375rem;
  color: #fefefe;
  background: #cc4b37;
  padding: 1.5rem; }
  #system-message a {
    color: #fefefe; }
  #system-message .alert-heading {
    color: #fefefe; }
  #system-message .alert-message,
  #system-message .alert-warning {
    position: relative; }
    #system-message .alert-message .close,
    #system-message .alert-warning .close {
      position: absolute;
      top: 0;
      right: 0;
      text-decoration: none;
      line-height: 1;
      font-size: 26px; }

.pagination {
  margin: 0 0.9375rem; }
  .pagination .counter {
    margin-top: 1rem; }
  .pagination ul {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    .pagination ul li {
      display: inline-block; }
      @media print, screen and (min-width: 40em) {
        .pagination ul li {
          margin-right: 8px; } }
      .pagination ul li:last-child {
        margin-right: 0; }
  .pagination .pagenav {
    display: inline-block;
    padding: 0.5rem 0.25rem;
    margin: 0 0.25rem 0 0;
    line-height: 1;
    text-decoration: none;
    width: 28px; }
  .pagination a.pagenav:hover {
    background: #5171B0;
    color: #F5F5F5; }
    .pagination a.pagenav:hover:before {
      border-color: #F5F5F5; }
    .pagination a.pagenav:hover:after {
      border-color: #F5F5F5; }
  .pagination .active .pagenav {
    background: #5171B0;
    color: #F5F5F5; }
  .pagination .pagination-next .pagenav,
  .pagination .pagination-end .pagenav,
  .pagination .pagination-start .pagenav,
  .pagination .pagination-prev .pagenav {
    font-size: 0;
    padding: 12px 0 12px 0; }
    .pagination .pagination-next .pagenav:before,
    .pagination .pagination-end .pagenav:before,
    .pagination .pagination-start .pagenav:before,
    .pagination .pagination-prev .pagenav:before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border-top: 2px solid #5171B0; }
  .pagination .pagination-next span.pagenav:before,
  .pagination .pagination-end span.pagenav:before,
  .pagination .pagination-start span.pagenav:before,
  .pagination .pagination-prev span.pagenav:before {
    border-top: 2px solid #6E6E6E;
    border-left: 2px solid #6E6E6E; }
  .pagination .pagination-next span.pagenav:after,
  .pagination .pagination-end span.pagenav:after,
  .pagination .pagination-start span.pagenav:after,
  .pagination .pagination-prev span.pagenav:after {
    border-top: 2px solid #6E6E6E;
    border-left: 2px solid #6E6E6E; }
  .pagination .pagination-end .pagenav:after,
  .pagination .pagination-start .pagenav:after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #5171B0; }
  .pagination .pagination-start .pagenav:before,
  .pagination .pagination-prev .pagenav:before {
    border-left: 2px solid #5171B0;
    transform: rotate(-45deg); }
  .pagination .pagination-start .pagenav:after {
    content: '';
    border-top: 2px solid #5171B0;
    border-left: 2px solid #5171B0;
    transform: rotate(-45deg);
    position: relative;
    left: 1px; }
  .pagination .pagination-start .pagenav:before {
    position: relative;
    left: 4px; }
  .pagination .pagination-prev .pagenav:before {
    position: relative;
    left: 2px; }
  .pagination .pagination-next .pagenav:before,
  .pagination .pagination-end .pagenav:before {
    border-right: 2px solid #5171B0;
    transform: rotate(45deg); }
  .pagination .pagination-next .pagenav:before {
    position: relative;
    right: 2px; }
  .pagination .pagination-end .pagenav:before {
    position: relative;
    left: -1px; }
  .pagination .pagination-end .pagenav:after {
    content: '';
    border-top: 2px solid #5171B0;
    border-right: 2px solid #5171B0;
    transform: rotate(45deg);
    position: relative;
    left: -4px; }

.info-post {
  margin: 0.625rem 0; }
  .info-post span {
    display: inline-block;
    margin: 0 0.625rem 0 0; }

#pdf {
  height: 910px; }

/* ZITAT */
.quote {
  min-height: 17rem;
  display: flex;
  align-items: center; }

.quote__title {
  font-family: "Prata", serif;
  font-size: 1.5rem; }

.quote__author {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1rem; }

.standardtext__line {
  border: 1px solid #cacaca;
  margin: 1.875rem 0.9375rem; }

@media print, screen and (min-width: 64em) {
  .slider-padding--1 {
    padding-left: 9.375rem;
    padding-right: 9.375rem; } }

@media print, screen and (min-width: 64em) {
  .slider-padding--2 {
    padding-left: 15.625rem;
    padding-right: 15.625rem; } }

@media print, screen and (min-width: 64em) {
  .slider-padding--3 {
    padding-right: 35%; } }

/* Varaibles - config */
/* SCSS*/
.org-chart {
  display: block;
  clear: both;
  margin-bottom: 30px;
  position: relative;
  overflow: visible;
  /**
  * For IE 6/7 only
  * Include this rule to trigger hasLayout and contain floats.
  */
  /* Box colors */
  /* 1 column */
  /* 2 column */
  /* 3 column */
  /* DEPARTMENTS COLUMNs */ }
  .org-chart.cf:before, .org-chart.cf:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */ }
  .org-chart.cf:after {
    clear: both; }
  .org-chart.cf {
    *zoom: 1; }
  .org-chart ul {
    padding: 0;
    margin: 0.9375rem 0 0 0;
    list-style: none; }
  .org-chart ul li {
    position: relative;
    display: block;
    margin: 0; }
    .org-chart ul li:before {
      display: none; }
    .org-chart ul li a {
      text-decoration: none; }
  .org-chart ul li span {
    display: block;
    text-align: center;
    overflow: hidden;
    text-decoration: none;
    color: #D9D9D9;
    background: #30446b;
    padding: 4px;
    height: 70px; }
    .org-chart ul li span.empty {
      background: transparent; }
  .org-chart ul li a:hover span {
    background: #405a8e;
    text-decoration: none; }
  .org-chart .lvl-a {
    color: #fefefe; }
  .org-chart .lvl-b {
    background: #637fb8;
    color: #D9D9D9;
    height: auto; }
    @media print, screen and (min-width: 40em) {
      .org-chart .lvl-b {
        height: 85px; } }
  .org-chart a:hover .lvl-b {
    background: #5171B0; }
  .org-chart .lvl-b-second {
    background: #3d5687; }
  .org-chart a:hover .lvl-b {
    background: #354b75; }
  .org-chart .board {
    width: 70%;
    margin: 0 auto;
    display: block;
    position: relative; }
    .org-chart .board:before {
      content: "";
      display: block;
      position: absolute;
      height: 177px;
      width: 0px;
      border-left: 2px solid #D9D9D9;
      margin-left: 49%;
      top: 15px; }
  .org-chart ul.columnOne {
    height: 90px;
    position: relative;
    width: 100%;
    display: block;
    clear: both; }
    .org-chart ul.columnOne li {
      width: 30%;
      margin: 0px auto;
      top: 20px;
      display: inline-block; }
  .org-chart ul.columnTwo {
    position: relative;
    width: 100%;
    display: block;
    height: 90px;
    clear: both; }
    .org-chart ul.columnTwo li:first-child {
      width: 30%;
      float: left; }
    .org-chart ul.columnTwo li {
      width: 30%;
      float: right; }
    .org-chart ul.columnTwo:before {
      content: "";
      display: block;
      position: relative;
      width: 80%;
      height: 10px;
      border-top: 2px solid #D9D9D9;
      margin: 0 auto;
      top: 40px; }
  .org-chart ul.columnThree {
    position: relative;
    width: 100%;
    display: block;
    clear: both;
    margin: 0; }
    .org-chart ul.columnThree li:first-child {
      width: 30%;
      float: left;
      margin-left: 0; }
    .org-chart ul.columnThree li {
      width: 30%;
      margin-left: 5%;
      float: left; }
    .org-chart ul.columnThree li:last-child {
      width: 30%;
      float: right;
      margin-left: 0; }
    .org-chart ul.columnThree:before {
      content: "";
      display: block;
      position: relative;
      width: 255px;
      height: 10px;
      border-top: 2px solid #D9D9D9;
      margin: 0 auto;
      top: 40px;
      left: 40px; }
    .org-chart ul.columnThree.columnThree--two-lines:before {
      width: 85%; }
  .org-chart .departments {
    width: 100%;
    display: block;
    clear: both;
    margin-top: 100px; }
    .org-chart .departments:before {
      content: "";
      display: block;
      width: 85%;
      height: 22px;
      border-top: 2px solid #D9D9D9;
      border-left: 2px solid #D9D9D9;
      border-right: 2px solid #D9D9D9;
      margin: 0 auto;
      top: 0px; }
  .org-chart .department {
    border-left: 2px solid #D9D9D9;
    width: 15.2%;
    float: left;
    margin: 0 4px; }
    .org-chart .department:after {
      content: "";
      position: absolute;
      display: block;
      width: 10px;
      height: 47px;
      border-left: 2px solid #D9D9D9;
      left: 50%;
      top: -22px; }
    .org-chart .department:first-child:after {
      display: none; }
    .org-chart .department:last-child:after {
      display: none; }
    .org-chart .department span {
      border-left: 1px solid #D9D9D9; }
    .org-chart .department li {
      padding-left: 25px;
      border-bottom: 2px solid #D9D9D9;
      height: 80px; }
    .org-chart .department li span {
      background: #D9D9D9;
      top: 40px;
      position: absolute;
      z-index: 1;
      width: 95%;
      vertical-align: middle;
      right: 0;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .org-chart .department li a:hover span {
      background: silver; }
    .org-chart .department .sections {
      margin-top: -20px;
      width: 100%; }
      .org-chart .department .sections span {
        color: black; }
        @media print, screen and (min-width: 40em) {
          .org-chart .department .sections span {
            font-size: 0.875rem; } }
        @media print, screen and (min-width: 64em) {
          .org-chart .department .sections span {
            font-size: 1rem; } }
  .org-chart .department-second {
    margin-top: 15px; }
    .org-chart .department-second:after {
      top: -37px;
      height: 37px; }

/* MEDIA QUERIES */
@media all and (max-width: 767px) {
  .org-chart .board {
    margin: 0px;
    width: 100%; }
  .org-chart .departments:before {
    border: none; }
  .org-chart .department {
    float: none;
    width: 100%;
    margin-left: 0;
    margin-bottom: 40px; }
    .org-chart .department:before {
      content: "";
      display: block;
      position: absolute;
      width: 15px;
      height: 60px;
      border-left: 2px solid #D9D9D9;
      z-index: 1;
      top: -45px;
      left: 0%;
      margin-left: -2px; }
    .org-chart .department:after {
      display: none; }
  .org-chart .department:first-child:before {
    display: none; } }

/*--------- TO BE REMOVED FROM YOUR CSS --*/
/* this is just to display the behaviour of responsive on codepen */
.responsive-content {
  width: 767px;
  margin: 0px auto; }
  .responsive-content .org-chart .board {
    margin: 0px;
    width: 100%; }
  .responsive-content .org-chart .departments:before {
    border: none; }
  .responsive-content .org-chart .department {
    float: none;
    width: 100%;
    margin-left: 0;
    margin-bottom: 40px; }
    .responsive-content .org-chart .department:before {
      content: "";
      display: block;
      position: absolute;
      width: 15px;
      height: 60px;
      border-left: 2px solid #D9D9D9;
      z-index: 1;
      top: -45px;
      left: 0%;
      margin-left: -2px; }
    .responsive-content .org-chart .department:after {
      display: none; }
  .responsive-content .org-chart .department:first-child:before {
    display: none; }

.vote-result-item {
  background: #fefefe;
  display: block;
  padding: 1rem;
  text-decoration: none; }
  .vote-result-item .vote-result-item_headline {
    font-size: 1.5rem;
    margin-bottom: 1.5rem; }
  .vote-result-item:hover .vote-result-item_link {
    text-decoration: underline; }
  .vote-result-item img {
    width: 100%;
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem; }
  .vote-result-item .vote-result-item_link {
    color: #4C4C4C;
    font-weight: bold; }
    .vote-result-item .vote-result-item_link:before {
      content: '»';
      margin: 0 0.3125rem 0 0; }

#searchForm {
  margin: 0 0.9375rem;
  background: #fefefe;
  padding: 1.5rem;
  margin-bottom: 1.875rem; }
  #searchForm legend, #searchForm .ordering {
    display: block;
    font-size: 1.125rem;
    margin: 0 0 0.5rem 0; }
  #searchForm .button {
    margin-bottom: 0; }

.search-page {
  padding: 2.8125rem 0; }
  @media print, screen and (min-width: 64em) {
    .search-page {
      padding: 4.0625rem 0; } }

.phrases-box {
  margin-top: 1.125rem; }
  .phrases-box label {
    display: inline-block;
    margin-right: 0.5rem; }

.search-row,
.search-setting-row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }

.search-row {
  align-items: center;
  margin-bottom: 0.9375rem; }
  @media print, screen and (min-width: 40em) {
    .search-row {
      margin-bottom: 1.5625rem; } }

.search-setting-row label {
  color: #3F3F3F;
  font-size: 100%;
  line-height: 1.5;
  margin-bottom: .5rem; }

.phrases-row,
.search-input-group {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .phrases-row,
    .search-input-group {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 40em) {
    .phrases-row,
    .search-input-group {
      flex: 1 1 0px;
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      min-width: 0; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    .phrases-row,
    .search-input-group {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.search-input-group .inputbox {
  margin: 0; }

.form-limit,
.search-submit-group {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .form-limit,
    .search-submit-group {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 40em) {
    .form-limit,
    .search-submit-group {
      flex: 0 0 auto;
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    .form-limit,
    .search-submit-group {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  @media print, screen and (min-width: 40em) {
    .form-limit .btn, .form-limit .btn-white, .form-limit .btn-blue,
    .search-submit-group .btn,
    .search-submit-group .btn-white,
    .search-submit-group .btn-blue {
      margin: 0; } }

.phrases-row {
  display: flex; }
  @media screen and (max-width: 49.9375em) {
    .phrases-row {
      flex-flow: column; } }

@media screen and (max-width: 49.9375em) {
  .phrases-group {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }
  @media screen and (max-width: 49.9375em) and (min-width: 40em) {
    .phrases-group {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

@media print, screen and (min-width: 40em) {
  .ordering-box {
    margin-left: 0.9375rem; } }

.form-limit {
  margin-left: auto; }

.searchintro {
  margin: 0 0.9375rem;
  border-top: 1px solid #D9D9D9;
  padding: 0.9375rem 0; }
  @media screen and (max-width: 49.9375em) {
    .searchintro {
      margin-top: 0.9375rem; } }
  .searchintro p {
    margin-bottom: 0; }

.search-results {
  margin: 0 0.9375rem;
  border: 1px solid #D9D9D9; }
  .search-results .result-title {
    display: block; }

.search-results-item {
  background: #fefefe;
  border-bottom: 1px solid #D9D9D9;
  padding: 1.25rem 0.9375rem; }
  @media print, screen and (min-width: 40em) {
    .search-results-item {
      padding: 1.25rem 1.5625rem; } }
  .search-results-item:nth-child(2n) {
    background: rgba(254, 254, 254, 0.5); }

.archive {
  padding: 2.8125rem 0; }
  @media print, screen and (min-width: 64em) {
    .archive {
      padding: 4.0625rem 0; } }

.filter-search {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  margin: 0 0.9375rem;
  display: flex;
  margin-bottom: 1.875rem;
  padding: 1.5rem;
  background: #fefefe; }
  .filter-search::before, .filter-search::after {
    display: table;
    content: ' '; }
  .filter-search::after {
    clear: both; }
  @media screen and (max-width: 39.9375em) {
    .filter-search {
      flex-flow: column; } }
  @media print, screen and (min-width: 40em) {
    .filter-search {
      margin-bottom: 2.8125rem;
      align-items: flex-end; } }

.filter-select-group {
  display: flex;
  align-items: flex-start; }
  @media screen and (max-width: 39.9375em) {
    .filter-select-group {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 0;
      padding-left: 0;
      flex-flow: column; } }
  @media print, screen and (min-width: 40em) {
    .filter-select-group {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
      padding-right: 0;
      padding-left: 0; } }

@media screen and (max-width: 39.9375em) {
  .filter__date-col {
    width: 100%;
    margin-bottom: 10px; } }

.filter__date-col .inputbox {
  box-shadow: none;
  margin: 0; }
  .filter__date-col .inputbox b {
    display: none; }

.filter__date-col .chzn-drop {
  margin-right: .9375rem;
  margin-left: .9375rem;
  width: 91%; }

@media print, screen and (min-width: 40em) {
  .filter__date-col {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    .filter__date-col {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

@media print, screen and (min-width: 40em) {
  .filter-submit-group {
    flex: 0 0 auto;
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    .filter-submit-group {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.filter-submit-group .button {
  margin-bottom: 0; }

#archive-items {
  border: 1px solid #D9D9D9;
  margin: 0 0.9375rem; }
  #archive-items .archive-item {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    background: #fefefe;
    border-bottom: 1px solid #D9D9D9;
    padding: 1.25rem 0.9375rem; }
    @media print, screen and (min-width: 40em) {
      #archive-items .archive-item {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
    @media print, screen and (min-width: 40em) {
      #archive-items .archive-item {
        padding: 1.25rem 1.5625rem; } }
    #archive-items .archive-item:nth-child(2n) {
      background: rgba(254, 254, 254, 0.5); }
    #archive-items .archive-item:last-child {
      border-bottom: 0; }
    #archive-items .archive-item h2 {
      font-size: 1.375rem; }
    #archive-items .archive-item dl {
      margin: 0; }
      #archive-items .archive-item dl:after {
        content: '';
        clear: both;
        display: block; }
      #archive-items .archive-item dl dt {
        float: left;
        clear: both;
        width: 75px; }
      #archive-items .archive-item dl dd {
        float: left; }

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y; }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px; }
    .owl-theme .owl-nav [class*='owl-']:hover {
      background: #869791;
      color: #FFF;
      text-decoration: none; }
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .owl-theme .owl-dots .owl-dot span {
      width: 10px;
      height: 10px;
      margin: 5px 7px;
      background: #D6D6D6;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity 200ms ease;
      border-radius: 30px; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #869791; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block;
    width: 100%;
    max-width: none; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slider-thumbnail-nav .slick-slide {
  margin: 5px; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }
