body {
  hyphens: auto;
}

.logo {

  img {
    width: 45px;
    height: 70px;
    float: left;
  }

  span {
    color: $white;
    display: block;
    float: left;
    margin: 0 0 0 rem-calc(10px);
  }

  .site-title {
    font-weight: 300;

    b {
      font-weight: 700;
      display: block;
    }
  }
}


iframe {
  border: none;
}

.width-100 {
  width: 100%;
}

.margin-top-0 {
  margin-top: 0rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.padding-left-1 {
  padding-left: 1rem;
}
