.post-view {
  padding: rem-calc(50px) 0;

  h1 {
    font-size: rem-calc(30px);
    margin: 0 0 rem-calc(20px) 0;

    @include breakpoint(medium) {
      font-size: rem-calc(36px);
      margin: 0 0 rem-calc(35px) 0;
    }
  }

  figure {
    margin: 0 0 rem-calc(20px) 0;

    img {
      width: 100%;
    }
  }
}
