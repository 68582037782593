// ------------------------------------------
//  In Content-View
// ------------------------------------------

article, .infobox {

  ul {
    list-style: none;


    li {
      display: flex;
      margin-bottom: rem-calc(5px);

      &:before {
        content: '»';
        margin: 0 rem-calc(10px) 0 0;
      }
    }
  }

  ol {
    list-style-position: inside;
  }
}


//  Download List
// ------------------------------------------

.download-list {

  li {

    &::before {
      display: none;
    }

    a {
      font-weight: 600;
      color: $body-font-color;

      &:hover {
        color: $blue-darken-1;
      }

      span {
        font-size: rem-calc(14px);
        color: $grey-darken-2;
        display: block;
      }
    }
  }
}