header {
  .menu {
    display: none;

    @include breakpoint(xlarge) {
      display: flex !important;
    }

    li {
      font-size: rem-calc(14px);

      &.parent {
        position: relative;
        transition: .3s;
        margin: 0 rem-calc(15px) 0 0;
      }

      &:nth-last-child(2) {
        margin: 0;

        > ul {

          .submenu.is-dropdown-submenu {
            right: calc(100% - 10px);
            left: inherit;
          }
        }
      }

      &:hover > ul,
      .is-active > ul,
      &:hover .submenu.first-sub {
        opacity: 1;
        visibility: visible;
      }

      .search-trigger {
        &:after {
          right: 3px;
        }
      }
    }

    a, span {
      color: $white;
      position: relative;
      z-index: 2;
      display: block;
      line-height: 1;
      text-decoration: none;
      padding: $menu-items-padding;
    }

    .submenu {
      position: absolute;
      top: 0;
      left: -10px;
      padding: rem-calc(10px);
      opacity: 0;
      visibility: hidden;
      transition: .3s;
      z-index: 1;
      margin: 0;
      white-space: nowrap;
      background: transparent;

      > li {
        display: block;
        margin: 0;

        > a, > span {
          background: $color-mirage;
        }
      }



      &.first-sub {
        padding: 64px rem-calc(10px) 0 rem-calc(10px);

        > li {
          &:hover > a,
          &.is-active > a,
          &:hover > span,
          &.is-active > span {
            background: lighten($color-mirage, 3%);
            color: #fefefe;
          }
        }
      }

      .is-dropdown-submenu {
        left: calc(100% - 10px);
        top: -10px;
        > li {
          &:hover > a {
            background: lighten($color-mirage, 3%);
          }
          > a {
            background: darken($color-mirage, 5%);
          }
        }
      }
    }
  }
}
.header-top {
  background: lighten($color-mirage, 0);

}
.meta-menu {
  margin: 0 0.9375rem 0 0;
  padding: rem-calc(5px) 0;
  float: right;

  li {
    display: inline;
    margin: 0 rem-calc(10px);
    font-size: rem-calc(13px);
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    &:hover > ul,
    .is-active > ul,
    &:hover .submenu.first-sub {
      opacity: 1;
      visibility: visible;
    }

    a {
      color: rgba($white, 0.8);
      text-decoration: none;
    }

    .submenu {
      position: absolute;
      top: 100%;
      left: 0;
      background: $blue-darken-3;
      display: none;
      z-index: 5;
      margin: 0;
      white-space: nowrap;
      padding-top: rem-calc(5px);

      > li {
        display: block;
        padding: 0;
        margin: 0;

        > a, span {
          line-height: 1;
          text-decoration: none;
          display: block;
          padding: 0.7rem 1rem;
        }

        &:hover > a,
        &.is-active > a {
          background: lighten($color-mirage, 3%);
          color: #fefefe;
        }
      }

      &.first-sub {
        padding: 64px 0 0 0;

        > li {
          &:hover > a,
          &.is-active > a {
            background: lighten($color-mirage, 3%);
            color: #fefefe;
          }
        }
      }

      .is-dropdown-submenu {
        left: 100%;
        background: darken($color-mirage, 3%);
        > li {
          &:hover > a {
            background: lighten($color-mirage, 3%);
          }
        }
      }
    }
  }
}