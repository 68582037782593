// * ———————————————————————————————————————————————————————— * //
// *    CONTENT BANNER
// * ———————————————————————————————————————————————————————— * //
.content_banner {
  background-color: #004C65;
  background-size: cover;
  background-position: center center;
  h1,
  p {
    color: #eee;
  }
  &.small {
    height: 20rem;
    >div {
      padding-top: 3rem;
    }
  }
  &.medium {
    height: 30rem;
    >div {
      padding-top: 5rem;
      @include breakpoint(medium) {
        padding-top: 10rem;
      }
    }
  }
  &.large {
    height: 40rem;
    >div {
      padding-top: 7rem;
      @include breakpoint(medium) {
        padding-top: 15rem;
      }
    }
  }
  &.top {
    background-position: center top;
  }
  &.center {
    background-position: center center;
  }
  &.bottom {
    background-position: center bottom;
  }
  .overlay0 {
    width: 100%;
    height: 100%;
  }
  .overlay20 {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .overlay40 {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

// * ———————————————————————————————————————————————————————— * //
// *    CONTENT Zwischenheadline
// * ———————————————————————————————————————————————————————— * //
.content_zwischenheadline {
  h3 {
    margin: 0;
  }
  h4 {
    color: #3F3F3F;
  }
}

// * ———————————————————————————————————————————————————————— * //
// *    CONTENT NEWS
// * ———————————————————————————————————————————————————————— * //
.content_news {
  display: block;
  background-color: white;
  padding: 1rem;
  -webkit-box-shadow: 1px 1px 4px 0 #D4D4D4;
  box-shadow: 0 1px 4px 0 #D4D4D4;
  transition: 0.2s;
  position: relative;
  height: 32rem;
  text-decoration: none;
  &:hover {
    -webkit-box-shadow: 2px 2px 8px 0 #D4D4D4;
    box-shadow: 0 4px 8px 0 #D4D4D4;
  }
  h4 {
    color: #333;
    font-size: 1.5rem;
  }
  h5 {
    font-family: "Lato", sans-serif;
    color: #333;
    font-size: 0.8rem;
  }
  img {
    margin-bottom: 0.5rem;
    width: 100%;
  }
  p {
    font-family: "Lato", sans-serif;
    min-height: 7rem;
    color: #3F3F3F;
    margin-bottom: 0.5rem;
  }
  .readmore {
    font-family: "Lato", sans-serif;
    color: #333;
    font-weight: bold;
    position: absolute;
    bottom: 1rem;
  }
}

.content_bildgrid {}

#content {
  h1 {
    @include breakpoint(small down) {
      font-size: rem-calc(30);
    }
  }

  img {
    max-width: none;
    width: 100%;
  }
}