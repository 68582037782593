.vote-result-item {
  background: $white;
  display: block;
  padding: 1rem;
  text-decoration: none;

  .vote-result-item_headline {
    font-size: rem-calc(24px);
    margin-bottom: rem-calc(24px);
  }

  &:hover .vote-result-item_link {
    text-decoration: underline;
  }

  img {
    width: 100%;
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .vote-result-item_link {
    color: $grey-darken-3;
    font-weight: bold;

    &:before {
      content: '»';
      margin: 0 rem-calc(5px) 0 0;
    }
  }
}
