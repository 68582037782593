.openerBildGroup {
  .column {
    @include grid-column(12);

    @include breakpoint(xmedium) {
      @include grid-column(4);
    }
  }
}

.openerBild {
  background-color: white;
  padding: 1rem;
  height: 30rem;
  position: relative;
  text-decoration: none;
  display: block;

  p {
    color: #3f3f3f;
  }

  .link {
    position: absolute;
    bottom: 1rem;
    &::before {
      content: "»";
      margin: 0 0.3125rem 0 0;
    }
  }
}

.openerBild__image {
  margin-bottom: 1rem;
}
