.header-teaser-container {
  //background: $dark-gray;
  height: 80vh;

  .row {
    height: 100%;
    position: relative;
  }

  &.small {
    height: 300px;
  }

  &.auto-height {
    height: auto;
  }
}

.header-teaser-image {
  background-size: cover;
  width: 100%;
  height: 100%;

  .row {
    height: 100%;
    position: relative;
  }

  .auto-height & {
    padding: rem-calc(35px) 0;
  }
}

.header-teaser-para {
  font-size: rem-calc(20px);
  margin: 0;
}

.header-teaser-content {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  background: rgba($black, 0.3);
  width: calc(100% - 40px);
  color: $white;
  padding: rem-calc(25px) rem-calc(25px);

  @include breakpoint(xlarge) {
    width: 60%;
  }


  .auto-height & {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0);
    width: 100%;
    padding: rem-calc(25px) rem-calc(25px);

    @include breakpoint(medium down) {
      left: 0;
    }
  }
}

.header-teaser-head {
  color: $white;
}
