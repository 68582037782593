.card {
  background: $white;
}

.card-head {
  font-size: rem-calc(18px);
  margin: 0 0 rem-calc(15px) 0;
}
.card-time {
  color: $grey-darken-1;
  font-size: rem-calc(12px);
  margin: 0 0 rem-calc(5px) 0;
  display: block;
}
.card-label {
  color: $grey-darken-1;
  font-size: rem-calc(14px);
  margin: 0 0 rem-calc(5px) 0;
  display: inline-block;
}
.card-desc {
  margin: 0 0 rem-calc(30px) 0;
}

.card-link {
  color: $card-font-color;

  &:before {
    content: '»';
    margin: 0 rem-calc(5px) 0 0;
  }
}


//  Infobox
// ------------------------------------------

.infobox {
  padding: rem-calc(20px);
  background: $white;
}



.teaser-panel {

}

.teaser-box-one, .teaser-box-two, .teaser-box-six, .teaser-box-seven {
  float: left;
  width: 33.3333%;
  height: 330px;
}

.teaser-box {
  padding: 5px;

  @include breakpoint(medium down) {
    padding: rem-calc(10px) rem-calc(20px);
  }

    figure {
    height: 100%;
    background-size: cover;
  }

  figcaption {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}


.teaser-box-three {
  box-sizing: border-box;
  float: right;
  width: 33.3333%;
  height: 660px;
}



.teaser-box-four, .teaser-box-eight {
  box-sizing: border-box;
  float: left;
  width: 66.6667%;
  height: 330px;
}

.teaser-box-one,
.teaser-box-two,
.teaser-box-three,
.teaser-box-four,
.teaser-box-six,
.teaser-box-seven,
.teaser-box-eight {

  @include breakpoint(medium down) {
    width: 50%;
    height: 350px;
  }
}

// * ———————————————————————————————————————————————————————— * //
// *    Card-Koppa // Gastgeberverzeichnis
// * ———————————————————————————————————————————————————————— * //



.abstand-all-null {


  .moduletable {

    .row--koppa {
      padding-bottom: rem-calc(16px);
      margin-bottom: rem-calc(16px);
      position: relative;

      &:after {
        content: '';
        display: block;
        width: calc(100% - 32px);
        border-bottom: 1px solid $grey-lighten-1;
        position: relative;
        top: 16px;
        left: 16px;
      }

      @include breakpoint(medium) {
        padding-bottom: rem-calc(40px);
        margin-bottom: rem-calc(40px);

        &:after {
          top: 40px;
        }
      }
    }

    &:nth-last-child(2) {
      .row--koppa {
        padding-bottom: 0;
        margin-bottom: 0;

        &:after {
          display: none;
        }
      }
    }
  }
}


.card--koppa {
  margin: 0;

  .card-head {
    padding: rem-calc(16px);
    margin: 0;
    display: block;
    font-weight: 600;
    font-family: $body-font-family;

    a {
      text-decoration: none;
      color: #757575;
    }
  }

  > a {
    display: block;
  }
}


.card--koppa-small > a {
  @include breakpoint(medium) {
    position: relative;
    height: 270px;
    overflow: hidden;

    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

}

.card--koppa-large {
  margin-bottom: rem-calc(20px);

  > a {
    display: block;

    img {
      width: 100% !important;
    }
  }
}