

.btn {
  border: 1px solid $grey-darken-2;
  padding: rem-calc(10px) rem-calc(20px);
  color: $grey-darken-2;
  border-radius: 25px;
  display: inline-block;
  margin: rem-calc(10px) 0 0 0;
  transition: .3s;
  text-decoration: none;
  cursor: pointer;
}

.btn-white {
  @extend .btn;
  border: 1px solid $white;
  color: $white;

  &:focus,
  &:hover {
    background: $white;
    color: $grey-darken-3;
  }
}

.btn-blue {
  @extend .btn;
  border: 1px solid $primary-color;
  background: $primary-color;
  color: $white;

  &:focus,
  &:hover {
    background: transparent;
    color: $primary-color;
  }
}

.btn-primary {
  color: $white;
  border: none;
}